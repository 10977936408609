import axios from "axios";
let url = "";
let statucurl = "";
if (process.env.NODE_ENV === "development") {
  url = "http://localhost:5750/api/";
  statucurl = "http://localhost:5750";
  // url = "https://api.folkus.jp/api/";
  // statucurl = "https://api.folkus.jp";
} else {
  url = "https://api.folkus.jp/api/";
  statucurl = "https://api.folkus.jp";
  // url = "https://api.magikfin.com/api/";
  // statucurl = "https://api.magikfin.com";
}
export const path = () => {
  return statucurl;
};
export default axios.create({
  baseURL: url,
});