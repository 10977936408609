const actions = {
    SET_IS_LOGIN: "SET_IS_LOGIN",
    SET_FISRT_NAME: "SET_FISRT_NAME",
    SET_LAST_NAME: "SET_LAST_NAME",
    SET_USER_TOCKEN: "SET_USER_TOCKEN",
    SET_BUSSINESS_NAME: "SET_BUSSINESS_NAME",
    SET_SIGNUP_NAME: "SET_SIGNUP_NAME",
    SET_PLAN:"SET_PLAN",
    SetData: (data: any) => {
      return {
        type: actions.SET_IS_LOGIN,
        data,
      };
    },
    SetfirstName: (data: any) => {
      return {
        type: actions.SET_FISRT_NAME,
        data,
      };
    },
    SetsignupName: (data: any) => {
      return {
        type: actions.SET_SIGNUP_NAME,
        data,
      };
    },
    SetlastName: (data: any) => {
      return {
        type: actions.SET_LAST_NAME,
        data,
      };
    },
    SetBussinessName: (data: any) => {
      return {
        type: actions.SET_BUSSINESS_NAME,
        data,
      };
    },
    SetUserTocken: (data: any) => {
      return {
        type: actions.SET_USER_TOCKEN,
        data,
      };
    },
    SetPlan: (data: any) => {
      return {
        type: actions.SET_PLAN,
        data,
      };
    },
  };
  
  export default actions;
  