import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import Header from "../components/ProfileHeader";
import Footer from "../components/Footer";
import Question from "../components/Question";
import Flowbutton from "../components/Flowbutton";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
declare var $: any;
interface Props {
  // history: any;
}
const Profilepage = (Props: Props) => {
  let navigate = useNavigate();
  let { id } = useParams();
  const [plans, setplans] = useState<Array<any>>([]);
  const [title, setTitle] = useState("")
  const [jptitle, setJptitle] = useState("")
  const [imagePath, setImagePath] = useState("")
  
  useEffect(() => {
   
    if (id && id.length > 0) {
      fetchPlansDetails(id || "")
      fetchPlans(id || "")   
     }
    return () => { };
  }, [id]);

  const fetchPlans = (id: string) => {
    API.get(`plancategory/getbyplan/${id}`)
      .then((data) => {
        if (data.data.status) {
          setplans(data.data.data);
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const fetchPlansDetails = (id: string) => {
    API.get(`plan/getbyid/${id}`)
      .then((data) => {
        console.log("plan deitlas",data.data);
        
        if (data.data.status) {
          setTitle(data.data.data.title)
          setJptitle(data.data.data.japness_title)
          setImagePath(data.data.data.image_path)
          //setplans(data.data.data);
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const goToDetails = (id: string) => {
    navigate(`/movies/${id}`, { replace: false });
  };
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <Header
        classnamebg="bg-profile"
        classnameimg={`${path()+ imagePath}`}
        title={title}
        japneestitle={jptitle}
      ></Header>
      {/* <div className="blog_data"></div> */}
      {plans.map((item: any, index: number) => {
        return (
          <div key={index} className="container-fluid my-5 py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5 mt-3 mobile-text">
                  <div>
                    <h1 className=" DamionFont underline-green ">{item.title}</h1>
                  </div>
                  <div className="mt-2 media-text">
                    <h3 className="fs-6 fw-semibold">{item.japness_title}</h3>
                  </div>
                  <div>
                    <h4
                      className="fw-bold my-4 dd"
                      style={{ color: "#1B3603" }}
                    >
                      {item.sort_description}
                    </h4>
                  </div>
                  <div className="discripation mt-3">
                    <p className="fs-5 hh">
                      
                      {item.description}
                    </p>
                  </div>
                <Link to={`/movies/${item.id}`}><button
                    type="button"
                    className="btn profile-button  text-dark DamionFont shadow border-light border-5" 
                    style={{ backgroundColor: "#B3D992",paddingBottom:28}}
                  >
                    See More
                  </button> </Link> 
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-4 py-3 border-0">
                  <Link to={`/movies/${item.id}`}>
                    <div className="card mini_video mx-auto d-block" >
                    <iframe src={item.video_link} width="100%" height="100%" frameBorder="0"  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                     </div>
                  </Link>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mt-5 ">
                <div className="col-md-1"></div>
                <div
                  className="col-md-10 doted-line "
                  style={{ color: "#739654" }}
                ></div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        );
      })}
      <Question></Question>
      <Flowbutton></Flowbutton>
      <Footer></Footer>
    </>
  );
};
export default Profilepage;
