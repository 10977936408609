import actions from "./actions";
import Api from "../../helper/Api";

const {
  SetData,
  SetfirstName,
  SetlastName,
  SetUserTocken,
  SetBussinessName,
  SetsignupName,
  SetPlan
} = actions;

const FetchLogin = () => {
  return async (dispatch: any) => {
    console.log(
      "useeeffect navbar 1",
      localStorage.hasOwnProperty("@tocken"),
      localStorage.getItem("@tocken")
    );
    try {
      if (localStorage.hasOwnProperty("@tocken")) {
        console.log("useeeffect navbar 2");
        let tocken = localStorage.getItem("@tocken");
        if (tocken) {
          console.log("useeeffect navbar 3");
          const AuthStr = "Bearer ".concat(tocken ? tocken : "");
          Api.get(`user/me`, {
            headers: { Authorization: AuthStr },
          })
            .then((data: any) => {
              if (data.data.status) {
                dispatch(SetData(true));
                dispatch(SetUserTocken(tocken));
                dispatch(SetfirstName(data.data.data.first_name));
                dispatch(SetlastName(data.data.data.last_name));
                if (data.data.bussinessdata) {
                  dispatch(SetBussinessName(data.data.bussinessdata.name));
                } else {
                  dispatch(SetBussinessName(""));
                }
              } else {
                dispatch(SetData(false));
              }
              console.log("me data", data.data.data);
            })
            .catch((error: any) => {
              dispatch(SetData(false));
            });
        } else {
          console.log("useeeffect navbar 4");
          dispatch(SetData(false));
        }
      } else {
        console.log("useeeffect navbar 5");
        dispatch(SetData(false));
      }
    } catch (err) {
      console.log("useeeffect navbar 6");
      dispatch(SetData(false));
    }
  };
};

const Fetchplan = () => {
  return async (dispatch: any) => {
    try {
      Api.get(`plan/getall/active`)
        .then((data: any) => {
          if (data.data.status) {
            dispatch(SetPlan(data.data.data));
          } else {
            dispatch(SetPlan([]));
          }
          console.log("me data", data.data.data);
        })
        .catch((error: any) => {
          dispatch(SetPlan([]))
        });

    } catch (err) {
      console.log("useeeffect navbar 6");
      dispatch(SetPlan([]))
    }
  };
};
const SetLoginTocken = (name: string) => {
  return (dispatch: any) => {
    dispatch(SetUserTocken(name));
  };
};
const SetSignupName = (name: string) => {
  return (dispatch: any) => {
    dispatch(SetsignupName(name));
  };
};

export { FetchLogin, SetLoginTocken, SetSignupName, Fetchplan };
