import React, { useState } from 'react';
import API, { path } from "../helper/Api";
import {
    useStripe, useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement, PaymentElement
} from '@stripe/react-stripe-js';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "27px",
            color: "#212529",
            fontSize: "1.1rem",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CheckoutForm(props: any) {
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: path()+'/api/order/update/payment',
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    // callback method to handle the response


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button className='btn btn-outline-danger  btn-block btn-lg shadow-0' disabled={!stripe}>支払い</button>
            </form>
        </React.Fragment>
    );
}