import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import { FetchLogin } from "../reducers/user/actionCreator";
interface Props {
 
}

const Flowbuttontwo = (Props: Props) => {
 
  return (
  
  <div className="container-fluid my-5  border-5 ">
  <div className="container">
    <div className="row pb-3 " style={{justifyContent:"center"}}>
     
      <Link to={"/flow"} className="col-md-8 col-sm-5 text-light py-2  " style={{ textDecoration: "none" }}>
        <div className="col-md-12  ">
          <img
            src="/img/deglog.png"
            className="img-disg img-mobile-view float-start"
            alt=""
          />
          <i className="fa-solid fa-angle-right float-end px-4 cross-icon"></i>
          <div
            className="border rounded-2 text-center py-3 shadow rounded border border-white"
            style={{
              backgroundImage:
                "linear-gradient(to Right,#739654 ,#7DB938)",
            }}
          >
            <span className="font-size-1">世界に１つだけの </span>
            <p className="font-size-1  ">オリジナルムービーを作ってみる</p>
          </div>
        </div>
      </Link>
  
    
    </div>
  </div>
  </div>
   
    );
}


export default Flowbuttontwo;

