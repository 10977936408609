import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
interface Props {
  plans?:Array<any>;
  // history: any;
}
const Footer = (Props: Props) => {

  useEffect(() => {
    return () => {};
  }, []);
  return (
    <>
      <div
        className="container-fluid footer-n py-4 "
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 ">
              <div className="row">
                <div className="col-md-3 ">
                  <Link className="footer-logo " to={"/"}>
                    <img
                      src="/img/logo.jpeg"
                      alt=""
                      className="logo-footer img-fluid"
                    />{" "}
                  </Link>
                </div>
                <div className="col-md-4 ">
                {Props.plans?.map((item,index)=>{
                                return  <ul  className="list-unstyled mb-0">
                                   <li className=""  style={{ textDecoration: "none",listStyle:"none"}}>
                                    <Link key={index} to={`/profile/${item.id}`}   style={{ textDecorationLine: "none" }} >
                                 <a className="text-dark text-decoration-none navfont "  >-{item.japness_title} </a> </Link>
                            </li>
                                </ul>
                            })}
                </div>
                <div className="col-md-3 ">
                  <ul className="list-unstyled">
                    <li className="" style={{ textDecorationLine: "none" }}>
                       {" "}
                        <a
                          href="/#planlink"
                          className="text-dark text-decoration-none navfont"
                        >
                          - その他ムービ
                        </a>
                    
                    </li>
                    <li className="">
                      <a
                        href="#questiondetails"
                        className="text-dark text-decoration-none navfont"
                      >
                        - よくある質問
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 ">
                  <ul className="list-unstyled">
                    <li className="">
                      <Link
                        to={"/mypage"}
                        style={{ textDecorationLine: "none" }}
                      >
                        {" "}
                        <a
                          href=""
                          className="text-dark text-decoration-none navfont"
                        >
                          - マイページ
                        </a>
                      </Link>
                    </li>
                    <li className="">
                      <Link to={"/cart"} style={{ textDecorationLine: "none" }}>
                        {" "}
                        <a
                          href=""
                          className="text-dark text-decoration-none navfont"
                        >
                          - カート
                        </a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="d-flex justify-content-lg-end justify-content-md-end">
                <Link to={"/contact"}>
                  {" "}
                  <button
                    type="button"
                    className="btn ft-buttons mx-2 shadow border-white"
                    style={{ color: "#274D07", backgroundColor: "#B3D992" }}
                  >
                    お問い合わせ
                  </button>
                </Link>
                <Link to={"/login"}>
                  <button
                    type="button"
                    className="btn ft-buttons shadow border-light border-5"
                    style={{ color: "#FFFFFF", backgroundColor: "#274D07" }}
                  >
                    ログイン
                  </button>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center my-3">
        <h6>FOLKUS All rights Reserved.</h6>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  //...state,
  plans: state.user.plans,
});
const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
