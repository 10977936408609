import React, { useEffect, useState } from "react";
import HeaderNoBg from "../components/HeaderNoBg";
import API, { path } from "../helper/Api";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CheckoutForm from "../components/CheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import 'moment/locale/ja';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Countries:Array<any> = [
  { label: "(01) 8:00 to 12:00", value: "8:00 to 12:00" },
  { label: "(02) 12:00 to 14:00", value: "12:00 to 14:00" },
  { label: "(03) 14:00 to 16:00", value: "14:00 to 16:00" },
  { label: "(04) 16:00 to 18:00", value: "16:00 to 18:00" },
  { label: "(05) 18:00 to 20:00", value: "18:00 to 20:00" },
  { label: "(06) 20:00 to 21:00", value: "20:00 to 21:00" },
];

const stripePromise = loadStripe("pk_test_51LBXr8DkRCcmAo6ZAlMqVJAAzoft0obYI8O8kafiFFr6cHavUDP6c8ZJuSLbWOmxklUV8L8ImxFqUmxuA8lp9ueB005EYSeDOi");
interface Props {
  // history: any;
}
const Address = (Props: Props) => {
  const [porcessingPayment, setporcessingPayment] = useState(false);
  const [paymnetsecreat, setpaymnetsecreat] = useState("")
  const [items, setitems] = useState([])
  const [shipping, setShipping] = useState(0)
  const [days, setDays] = useState(0)
  const [total, settotal] = useState(0)
  const [gtotal, setgtotal] = useState(0)
  const [shipping_address_name, setShipping_address_name] = useState("")
  const [shipping_address_postal_code, setShipping_address_postal_code] = useState("")
  const [shipping_address_prefecture, setShipping_address_prefecture] = useState("")
  const [shipping_address_line1, setShipping_address_line1] = useState("")
  const [shipping_address_line2, setShipping_address_line2] = useState("")
  const [shipping_address_phone, setShipping_address_phone] = useState("")
  const [shipping_address_email, setShipping_address_email] = useState("")
  const [shipping_address_remark, setShipping_address_remark] = useState("")
  const [shipping_address_timeZone, setShipping_address_timeZone] = useState([])
  const [billing_address_name, setBilling_address_name] = useState("")
  const [billing_address_postal_code, setBilling_address_postal_code] = useState("")
  const [billing_address_prefecture, setBilling_address_prefecture] = useState("")
  const [billing_address_line1, setBilling_address_line1] = useState("")
  const [billing_address_line2, setBilling_address_line2] = useState("")
  const [billing_address_phone, setBilling_address_phone] = useState("")
  const [isSameBilling, setisSameBilling] = useState(true);
  useEffect(() => {
    fetchPlans();
    return () => { };
  }, []);
  const fetchPlans = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.get(`order/invoice/preview`, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {
        console.log("order paln", data.data);
        if (data.data.status) {
          setitems(data.data.data.itemdata)
          setShipping(data.data.data.shipping)
          setDays(data.data.data.days)
          settotal(data.data.data.total)
          setgtotal(data.data.data.gtotal)


        } else {

        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);

      });
  }
  const PlaceOrder = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.post(`order/add/order`, {
      shipping_address_name,
      shipping_address_postal_code,
      shipping_address_prefecture,
      shipping_address_line1,
      shipping_address_line2,
      shipping_address_phone,
      shipping_address_email,
      shipping_address_remark,
      shipping_address_timeZone,
      isSameBilling,
      billing_address_name,
      billing_address_postal_code,
      billing_address_prefecture,
      billing_address_line1,
      billing_address_line2,
      billing_address_phone
    }, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {
        console.log("patch paln", data.data);
        if (data.data.status) {
          console.log("patch oder", data.data.data);

          setpaymnetsecreat(data.data.data)
          setporcessingPayment(true);
          // fetchPlans();
          // toast("新たな商品がカートに追加されました", { type: 'success' });
          //navigate("/cart", { replace: false });

        } else {
          toast("しばらくしてから再度、お試しください", { type: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          console.log("52");
          if (error.response.data.valData) {
            let dataerrer = error.response.data.valData.errors.map(
              (item: any, index: number) => {
                if (item.nestedErrors) {
                  return { name: item.param, message: item.msg, nested: item.nestedErrors };
                } else {
                  return { name: item.param, message: item.msg };
                }
              }
            );
            for (let index = 0; index < dataerrer.length; index++) {
              const element = dataerrer[index];
              if (element.nested) {
                for (let index1 = 0; index1 < element.nested.length; index1++) {
                  const nested = element.nested[index1];
                  toast(nested.msg, { type: 'error' });
                }
              }
              toast(element.message, { type: 'error' });
              console.log("5sdsdf2");
            }
          } else {
            console.log("451");
            toast(error.response.data.message, { type: 'error' });
          }
        } else {
          console.log("41");
        }
        console.log("error,error", error.response);

      });
  }
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <>
      <HeaderNoBg></HeaderNoBg>
      <ToastContainer />
      <div className="ccontainer-fluid my-5 blog_data">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 border-end border-2 px-5">
              <h2>お客様情報の入力</h2>
              <div className="row my-3 text-lg-start ">
                <label
                  htmlFor="username"
                  className=" col-form-label card-label-color"
                >
                  お名前
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="username"
                    value={shipping_address_name}
                    onChange={(e) => { setShipping_address_name(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row">
                <div className=" col-6 mb-3 text-lg-start">
                  <label
                    htmlFor="zip-code"
                    className=" col-form-label card-label-color"
                  >
                    郵便番号
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control rounded-0"
                      id="text"
                      value={shipping_address_postal_code}
                      onChange={(e) => { setShipping_address_postal_code(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="col-6 mb-3 text-lg-start">
                  <label
                    htmlFor="text"
                    className=" col-form-label card-label-color"
                  >
                    都道府県
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control rounded-0"
                      id="text"
                      value={shipping_address_prefecture}
                      onChange={(e) => { setShipping_address_prefecture(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3 text-lg-start">
                <label
                  htmlFor="address"
                  className=" col-form-label card-label-color"
                >
                  市区町村
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control mb-3 rounded-0"
                    id="text"
                    value={shipping_address_line1}
                    onChange={(e) => { setShipping_address_line1(e.target.value) }}
                  />
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="text"
                    value={shipping_address_line2}
                    onChange={(e) => { setShipping_address_line2(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row mb-3 text-lg-start">
                <label
                  htmlFor="phone"
                  className=" col-form-label card-label-color"
                >
                  電話番号
                </label>
                <div className="">
                  <input
                    type="tel"
                    className="form-control rounded-0"
                    id="phone"
                    value={shipping_address_phone}
                    onChange={(e) => { setShipping_address_phone(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row mb-3 text-lg-start">
                <label
                  htmlFor="email"
                  className=" col-form-label card-label-color"
                >
                  メールアドレス
                </label>
                <div className="">
                  <input
                    type="email"
                    className="form-control rounded-0"
                    id="email"
                    value={shipping_address_email}
                    onChange={(e) => { setShipping_address_email(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row mb-3 text-lg-start">
                <label
                  htmlFor="text"
                  className=" col-form-label card-label-color"
                >
                  備考欄(オプション)
                </label>
                <div className="">
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="text"
                    value={shipping_address_remark}
                    onChange={(e) => { setShipping_address_remark(e.target.value) }}
                  />
                </div>
              </div>
              <div className="row mb-4 text-lg-start">
                <label
                  htmlFor="text"
                  className=" col-form-label card-label-color"
                >
                  ・配送時間帯を下記からお選びください
                </label>
                <div className="">
                
                  <Select options={Countries} 
                  value={shipping_address_timeZone}
                    isMulti
                    onChange={(e: any) => {
                      setShipping_address_timeZone(e)
                    }} />

                </div>
              </div>
              <div className="row px-5 mt-3 mb-5">
                <div className="form-check">
                  <input
                    className="form-check-input mt-3  "
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={!isSameBilling}
                    onClick={() => setisSameBilling(!isSameBilling)}
                  />
                  <label
                    className="form-check-label fs-3 fw-semibold px-2"
                    htmlFor="flexRadioDefault1"
                  >
                    別の住所へ配送しますか？
                  </label>

                </div>
              </div>
              {!isSameBilling &&
                <form className="row g-3">

                  <div className="col-12">
                    <label htmlFor="お名前" className="form-label fs-5">
                      お名前
                    </label>
                    <input
                      type="text"
                      className="form-control  rounded-0"
                      id="お名前"
                      placeholder=" "
                      value={billing_address_name}
                      onChange={(e) => { setBilling_address_name(e.target.value) }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="input郵便番号" className="form-label fs-5">
                      郵便番号
                    </label>
                    <input type="text" className="form-control  rounded-0" id="郵便番号"
                      value={billing_address_postal_code}
                      onChange={(e) => { setBilling_address_postal_code(e.target.value) }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="input都道府県" className="form-label fs-5">
                      都道府県
                    </label>
                    <input
                      type="text"
                      className="form-control  rounded-0"
                      id="input都道府県"
                      value={billing_address_prefecture}
                      onChange={(e) => { setBilling_address_prefecture(e.target.value) }}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="input市区町村" className="form-label fs-5">
                      市区町村
                    </label>
                    <input
                      type="text"
                      className="form-control  rounded-0"
                      id="input市区町村"
                      placeholder=" "
                      value={billing_address_line1}
                      onChange={(e) => { setBilling_address_line1(e.target.value) }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      className="form-control  rounded-0"
                      id="input市区町村2"
                      placeholder=" "
                      value={billing_address_line2}
                      onChange={(e) => { setBilling_address_line2(e.target.value) }}
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="input電話番号" className="form-label fs-5">
                      電話番号
                    </label>
                    <input
                      type="number"
                      className="form-control  rounded-0"
                      id="input電話番号"
                      placeholder=" "
                      value={billing_address_phone}
                      onChange={(e) => { setBilling_address_phone(e.target.value) }}
                    />
                  </div>
                </form>}

            </div>
            <div className="col-md-4 px-5">
              <h5 className="text-dark">ご注文</h5>
              <div className="d-flex justify-content-between my-3 border-bottom border-2">
                <h5 className="text-dark">商品</h5>
                <p className="card-label-color">小計</p>
              </div>
              {items.map((item: any, index: number) => {
                return <div key={index} className=" my-2 border-bottom border-2">
                  <p>{item.name}</p>
                  <p className="card-label-color float-end">¥{(item.total).toLocaleString('hi-IN')}</p>
                  <h6 className="text-dark fw-semibold">
                  お届け日:{moment().locale('ja').add(20, 'd').format('ll')}
                  </h6>
                  <p className="card-label-color">x{item.qty}</p>
                </div>
              })}

              <div className="d-flex justify-content-between my-2 border-bottom border-2">
                <p className="card-label-color">小計</p>
                <p className="card-label-color">¥{total.toLocaleString('hi-IN')}</p>
              </div>
              <div className="d-flex justify-content-between my-2 border-bottom border-2">
                <p className="card-label-color">配送</p>
                <p className="card-label-color">送料：￥{shipping.toLocaleString('hi-IN')}</p>
              </div>
              <div className="d-flex justify-content-between my-2">
                <p className="card-label-color text-dark">合計</p>
                <h3 className="fw-bold">¥{gtotal.toLocaleString('hi-IN')}</h3>
              </div>
              {!porcessingPayment && <button type="button" onClick={() => {
                PlaceOrder();
              }} className="btn btn-outline-danger  btn-block btn-lg shadow-0"
                data-mdb-ripple-color="dark">お支払いへ進む</button>}
              {porcessingPayment &&
                <div className="col-md-12">
                  <Elements stripe={stripePromise} options={{ clientSecret: paymnetsecreat }} >
                    <CheckoutForm setPaymentCompleted={(e: any) => {

                    }} />
                  </Elements>
                </div>}
            </div>

          </div>


        </div>
      </div>


      <Footer></Footer>
    </>
  );
};
export default Address;
