import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Question from "../components/Question";
import Flowbutton from "../components/Flowbutton";
interface Props {
  // history: any;
}
const FllowPage = (Props: Props) => {
  useEffect(() => {
    return () => { };
  }, []);
  useEffect(() => {
    return () => { };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);

  }, [])
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <Header
        classnamebg="bg-profile"
        title="Flow"
        japneestitle="制作の流れ"
      ></Header>
      <div className="bg-colour">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className=" mt-5 mobile-text">
                <div>
                  <h4 className="text-center  heading-flow-line DamionFont">
                    Step.1
                  </h4>
                </div>
                <div className="my-4">
                  <h4 className="text-center " style={{ color: "#1B3603" }}>
                    テンプレートを購入1
                  </h4>
                </div>
                <div className="discripation mb-5">
                  <p className="fs-6 text-center fw-semibold">
                    お好みのテンプレートが決まれば、カートに入れ子決済手続きを行います。{" "}
                    <br /> お支払い方法は、下記があります。 <br />
                    VISA / マスターカード / JCB / AMERICAN EXPRESS。
                  </p>
                </div>
              </div>
              <div className="card-1 ">
                <img src="img/signup.png" className="card-box3 " alt="..." />
              </div>
              <div className="col-4"></div>
              <div className="col-2 mx-auto d-block mrginHalf">
                {" "}
                <img
                  src="/img/path.png"
                  height={36}
                  className=" between  b-block img-fluid"
                  alt=""
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid  bg-colour-first">
          <div className="container   ">
            <div className="row">
              <div className=" mt-5 mobile-text">
                <div>
                  <h4 className="text-center  heading-flow-line DamionFont">
                    Step.2
                  </h4>
                </div>
                <div className="my-4">
                  <h4 className="text-center " style={{ color: "#1B3603" }}>
                    会員登録
                  </h4>
                </div>
                <div className="discripation mb-5">
                  <p className="fs-6 text-center fw-semibold">
                    会員登録は無料で行うことができます。
                    <br />
                    担当者とのやりとりや、画像の入稿などはマイページ上で行いますので、会員登録が
                    <br /> 必須となります。
                  </p>
                </div>
              </div>
              <div className="card-1 ">
                <img src="img/second.png" className="card-box2 " alt="..." />
              </div>
              <div className="col-4"></div>
              <div className="col-2 mx-auto d-block mrginHalf">
                {" "}
                <img
                  src="/img/path.png"
                  height={36}
                  className=" between  b-block img-fluid"
                  alt=""
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid  ">
          <div className="container  ">
            <div className="row">
              <div className=" mt-5 mobile-text">
                <div>
                  <h4 className="text-center  heading-flow-line DamionFont">
                    Step.3
                  </h4>
                </div>
                <div className="my-4">
                  <h4 className="text-center " style={{ color: "#1B3603" }}>
                    画像・テキストを挿入
                  </h4>
                </div>
                <div className="discripation mb-5">
                  <p className="fs-6 text-center fw-semibold">
                    マイページでお選びいただいたテンプレートのサンプルムービーを見ることができ{" "}
                    <br />{" "}
                    ます。画像・テキスト挿入画面には、番号を降っているので、お好きな画像・テキ{" "}
                    <br /> ストをご希望の番号に挿入してください。
                  </p>
                </div>
              </div>
              <div className="card-1 ">
                <img src="img/movielogo.png" className="card-box3 " alt="..." />
              </div>
              <div className="col-4"></div>
              <div className="col-2 mx-auto d-block mrginHalf">
                {" "}
                <img
                  src="/img/path.png"
                  height={36}
                  className=" between  b-block img-fluid"
                  alt=""
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid  bg-colour-first">
          <div className="container  ">
            <div className="row">
              <div className=" mt-5 mobile-text">
                <div>
                  <h4 className="text-center  heading-flow-line DamionFont">
                    Step.4
                  </h4>
                </div>
                <div className="my-4">
                  <h4 className="text-center" style={{ color: "#1B3603" }}>
                    入稿
                  </h4>
                </div>
                <div className="discripation mb-5">
                  <p className="fs-6 text-center fw-semibold">
                    画像・テキストが完成しましたら、入稿手続きを行います。
                    <br />
                    修正や不明点がありましたら、マイページ上のチャットからご連絡いたします。
                  </p>
                </div>
              </div>
              <div className="card-1 ">
                <img src="img/sidemovie.png" className="card-box2 " alt="..." />{" "}
              </div>
              <div className="col-4"></div>
              <div className="col-2 mx-auto d-block mrginHalf">
                {" "}
                <img
                  src="/img/path.png"
                  height={36}
                  className=" between  b-block img-fluid"
                  alt=""
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </div>
        <div className="container-fluid  ">
          <div className="container  ">
            <div className="row">
              <div className=" mt-5 mobile-text col-md-12">
                <div>
                  <h4 className="text-center  heading-flow-line DamionFont">
                    Step.5
                  </h4>
                </div>
                <div className="my-4">
                  <h4 className="text-center" style={{ color: "#1B3603" }}>
                    納品
                  </h4>
                </div>
                <div className="discripation ">
                  <p className="fs-6 text-center fw-semibold">
                    お二人で作ったオリジナルのムービーは約一ヶ月ほどで納品いたします。納品形態は <br />
                    下記ございます。
                  </p>
                </div>

                <div className="box row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3 d-flex justify-content-around">
                    <span>
                      {" "}
                      <i
                        className="fa fa-check-square-o  "
                        style={{ color: "#739654" }}
                        aria-hidden="true"
                      >
                        {" "}
                      </i>
                      &nbsp; データ納品
                    </span>
                    <span>
                      <i
                        className="fa fa-check-square-o "
                        style={{ color: "#739654" }}
                        aria-hidden="true"
                      >
                        {" "}
                      </i>{" "}
                      &nbsp; USB納品
                    </span>

                  </div>
                  <div className="col-md-3 d-flex justify-content-around ">
                    <span>
                      <i
                        className="fa fa-check-square-o "
                        style={{ color: "#739654" }}
                        aria-hidden="true"
                      >
                        {" "}
                      </i>
                      &nbsp; DVD納品
                    </span>
                    <span>
                      <i
                        className="fa fa-check-square-o "
                        style={{ color: "#739654" }}
                        aria-hidden="true"
                      >
                        {" "}
                      </i>{" "}
                      &nbsp; Blu-ray
                    </span>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div className="card-1 ">
                  {/* <img src="/img/circle.png" className="card-box3-cicle" alt="..." /> */}
                  <img src="img/moviecart5.png" className="card-box3 flow6img" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row bg-light " style={{ minHeight: 50 }}></div>
      </div>
      <Question></Question>
      <Flowbutton></Flowbutton>
      <Footer></Footer>
    </>
  );
};
export default FllowPage;
