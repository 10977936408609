import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
interface Props {
  // history: any;
}
const AccountInfoPage = (Props: Props) => {
  const [email, setEmail] = useState("")
  const [userId, setUserId] = useState("")
  const [password, setPassword] = useState("")
  useEffect(() => {
    let tocken = localStorage.getItem("@tocken");
    if (!tocken) {

    }

    fetchPlans();
    return () => { };
  }, []);
  const fetchPlans = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.get(`user/me`, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {
        console.log("patch paln", data.data);
        if (data.data.status) {
          setEmail(data.data.data.email)
          setUserId(data.data.data.username)


        } else {
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const updatePassword = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.patch(
      `user/update/password`,
      { password: password },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          toast(data.data.message, { type: 'success' });
          fetchPlans();
        } else {
          toast("しばらくしてから再度、お試しください", { type: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          console.log("52");
          if (error.response.data.valData) {
            let dataerrer = error.response.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            for (let index = 0; index < dataerrer.length; index++) {
              const element = dataerrer[index];
              toast(element.message, { type: 'error' });
              console.log("5sdsdf2");
            }
          } else {
            console.log("451");
            toast(error.response.data.message, { type: 'error' });
          }
        } else {
          console.log("41");
        }
        console.log("error,error", error.response);
      });
  };
  useEffect(() => {
    return () => { };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);

  }, [])
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <ToastContainer />
      <HeaderNoBg></HeaderNoBg>

      <div className="container-fulid py-5">
        <div className="container ">
          <div className="row py-0 my-0 margin0">
            <div
              className="col-md-3 border-end  border-dark reg-btn-1"
              style={{ height: 413 }}
            >
              <ul className="mt-2 Registerpage-ul">
                <Link to={"/password"}> <a className="text-dark">
                  <li className="Registerpage-padd">・登録内容の確認・変更</li>
                </a></Link>
                <Link to={"/reset"}>    <a href="" className="text-dark">
                  <li className="Registerpage-padd fw-semibold">
                    ・パスワードの変更
                  </li>
                </a></Link>

                <Link to={"/myorder"}> <a href="" className="text-dark">
                  <li className="Registerpage-padd fw-semibold">
                    ・入稿ページ
                  </li>
                </a></Link>
              </ul>
            </div>
            <div className="col-md-9 mb-0 px-4 my-0 margin0">
              <div className="mb-0 margin0">
                <span className="fs-5 DamionFont" style={{ color: "#739654" }}>
                  Account info
                </span>
                <h2>アカウント情報</h2>
                <hr />
                <div className="row mx-4">
                  <div className="col-md-3">
                    <ul className="list-unstyled ">
                      <li className="">メールアドレス</li>
                      <li className="mt-4">ユーザーID {">"} </li>
                      <li className="mt-4">パスワード </li>
                    </ul>
                  </div>

                  <div className="col-md-3">
                    <ul className="list-unstyled ">
                      <li className="">{email}</li>
                      <li className="mt-4">{userId} </li>
                      <li className="mt-4"> <input
                        type="password"
                        className="form-control shadow-inner"
                        id="inputText"
                        placeholder="名前を入力してください"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                      /></li>
                    </ul>
                  </div>
                  <div className="col-md-6">

                  </div>
                </div>

              </div>

            </div>
            <button
              type="button"
              onClick={() => { updatePassword() }}
              className="btn btn-outline-danger mx-auto d-block rounded-0 Registerpage-btn hidebutton-registration"
            >
              編集する
            </button>

          </div>

          <div className="row reg-btn-2">
            <ul className="mt-2 list-unstyled">
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・登録内容の確認・変更
                </li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・パスワードの変更
                </li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・お支払い方法
                </li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">・配送先住所</li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">・入稿ページ</li>
              </a>
            </ul>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};
export default AccountInfoPage;
