import actions from "./actions";

const {
  SET_IS_LOGIN,
  SET_FISRT_NAME,
  SET_USER_TOCKEN,
  SET_LAST_NAME,
  SET_BUSSINESS_NAME,
  SET_SIGNUP_NAME,
  SET_PLAN
} = actions;

const initState = {
  is_login: false,
  first_name: "",
  last_name: "",
  tocken: "",
  signup_name: "",
  bussness_name: "",
  plans:[]
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case SET_IS_LOGIN:
      return {
        ...state,
        is_login: data,
      };
    case SET_FISRT_NAME:
      return {
        ...state,
        first_name: data,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        last_name: data,
      };
    case SET_USER_TOCKEN:
      return {
        ...state,
        tocken: data,
      };
    case SET_BUSSINESS_NAME:
      return {
        ...state,
        bussness_name: data,
      };
    case SET_SIGNUP_NAME:
      return {
        ...state,
        signup_name: data,
      };
      case SET_PLAN:
      return {
        ...state,
        plans: data,
      };

    default:
      return state;
  }
};
export default AuthReducer;
