import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import { colors } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
interface Props {
    // history: any;
}
const Mypage = (Props: Props) => {
    let navigate = useNavigate();
    const [cart_item, setcart_item] = useState<Array<any>>([])
    const [total, settotal] = useState(0)
    useEffect(() => {
        fetchPlans();
        return () => { };
    }, []);
    const fetchPlans = () => {
        let tocken = localStorage.getItem("@tocken");
        const AuthStr = "Bearer ".concat(tocken ? tocken : "");
        API.get(`order/get/user/all/1/200`, {
            headers: { Authorization: AuthStr },
        })
            .then((data) => {
                console.log("patch paln", data.data);
                if (data.data.status) {
                    setcart_item(data.data.data)




                } else {

                }
            })
            .catch((error) => {
                console.log(error);

                console.log("error,error", error.response);

            });
    }
    const renderButton = (qty: number, id: string, isDone: Array<number>) => {
        let arr: Array<any> = [];
        for (let index = 0; index < qty; index++) {
            if (isDone[index] == 0) {
                arr.push(<button type="button" onClick={() => {
                    navigate(`/upload/${id}/${index + 1}`, { replace: false });
                }} className="btn btn-outline-dark btn-sm  mx-auto d-block btn-mar rounded-0 mb-1">写真、テキストを入稿する
                </button>)
            }
        }
        return arr;
    }
    useEffect(() => {
        return () => {};
      }, []);
      useEffect(() => {
        setTimeout(() => {
          scrollToTop()
        }, 300);
       
      }, [])
      const scrollToTop = () =>{ 
      
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    return (
        <>
            <HeaderNoBg></HeaderNoBg>

            <div className="container-fluid my-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto d-block  ">
                            <div className="row">
                                <span className="fs-5" style={{ color: "#739654" }}>My Order</span>
                                <div className="col">
                                    <span className="fs-6 fw-semibold">フルオーダーパック</span>
                                </div>
                            </div>
                            <hr className="hr1" style={{ backgroundColor: "black" }} />
                        </div>
                    </div>
                    <div className="row pb-5">

                        <div className="col-md-4 col-3 text-center">

                            <h5 className="col-ml-3 fw-semibold">商品</h5>
                        </div>
                        <div className="col-md-4 col-3 text-center" >
                            <h5 className="fw-semibold">金額</h5>

                        </div>
                        <div className="col-md-4 col-6 text-center">
                            <h5 className="fw-semibold">オプション</h5>

                        </div>

                    </div>
                    {cart_item.map((item, index) => {
                        return <div key={index} className="row pb-5">
                            <div className="col-md-4 col-3 text-center">
                                <span className="list-padd">{item.plan_category[0].title}</span>
                            </div>
                            <div className="col-md-4 col-3 text-center">
                                <span className="list-padd">¥{(item.total).toLocaleString('hi-IN')}</span>
                            </div>
                            <div className="col-md-4 col-6 text-center">
                                {renderButton(item.qty, item.id, item.is_file_done)}
                            </div>
                        </div>
                    })}

                </div>
            </div>

            <Footer></Footer>
        </>
    );
};
export default Mypage;
