import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import { FetchLogin } from "../reducers/user/actionCreator";
interface Props {
  innerRef?:any
}

const Question = (Props: Props) => {
 


  return (      
    <div
    ref={Props.innerRef}
    className="container-fluid  py-5"
    style={{ backgroundColor: "#F8F8F8"}}
    id="questiondetails"
  >
    <div className="container py-5">
      <div className="row">
        <div className="col-md-5">
          <p className="part3 ">
            <h2 className="underline-green  DamionFont">Question</h2>
            <p className=" fs-4 fw-bold">よくある質問</p>
          </p>
        </div>
        <div className="col-md-7 ">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item mb-3 shadow border-0 ">
              <h2 className="accordion-header " id="headingOne">
                <button
                  className="accordion-button  text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
              <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                 <span> 写真の枚数は増減可能でしょうか？</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show border-0 bg-light"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success ">
                   <span className="fw-bold DamionFont fs-5 ">A.</span> 一部のムービーを除き、可能です。<br/>オプションよりご購入ください。<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>
                  素材は写真だけでしょうか？動画の利用は可能ですか？
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse  "
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success bg-light">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  使用できる素材は写真のみとなり、動画素材やアニメーションは利用できません。<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  楽曲は変更できますか？
                </button>
              </h2>
              <div
                id="collapseThree" 
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 bg-light ">A.</span> 
                  "サンプルムービー内の楽曲の他、各ムービーの雰囲気に合わせたロイヤリティフリーの音源を用意しています。<br/>こちらで用意している音源の利用は、申請・追加料金などは不要です。<br/>
                   別途、費用が発生しますが、J-POPや洋楽などの市販曲の利用も可能です。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingfour">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  最短でどのくらいでDVDを納品できますか？
                </button>
              </h2>
              <div
                id="collapsefour"
                className="accordion-collapse collapse  "
                aria-labelledby="headingfour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success bg-light">
                <span className="fw-bold DamionFont fs-5  ">A.</span> 
                  "写真とコメントをいただいてから、20日程度での納品が一般的ですが、納期短縮は可能な場合もあります。<br/>
                   素材がそろってから14日以内の納品希望の場合は、ご購入前にお問い合わせください。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingfive">
                <button
                  className="accordion-button collapsed   text-dark bg-white" 
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>
                  パソコンを持っていませんが、依頼可能ですか？
                </button>
              </h2>
              <div
                id="collapsefive"
                className="accordion-collapse collapse bg-light "
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  もちろん大丈夫です。<br/>コメントの記入や写真の送付は、スマートフォンからでも可能です。<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingsix">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesix"
                  aria-expanded="false"
                  aria-controls="collapsesix"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  納品はDVDだけでしょうか？
                </button>
              </h2>
              <div
                id="collapsesix"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingsix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  MP4データも別途オプションでお渡し可能です。<br/>また、高画質なBlu-rayディスクもご選択いただけます。<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingseven">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  送られてきたDVDが再生できません。<br/>
                </button>
              </h2>
              <div
                id="collapseseven"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingseven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "弊社にてDVDの焼き付けを行なった機器と再生機器との相性の問題が稀にございます。<br/> 
                   ごく一般的な標準のレコーダーであれば再生可能ですので、別のプレーヤーで再生をお試しください。<br/> それでも再生できない場合は早急にお知らせください。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingeight">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseeight"
                  aria-expanded="false"
                  aria-controls="collapseeight"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  プリクラや小さいサイズの写真も使用できますか？
                </button>
              </h2>
              <div
                id="collapseeight"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingeight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "はい、どちらも使用できます。<br/>
                   その他の写真と同じサイズまで拡大されますので、写真サイズが小さいものほど画質が荒くなってしまいますのであらかじめご了承ください。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingnine">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsenine"
                  aria-expanded="false"
                  aria-controls="collapsenine"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  コメント作成を代行してもらえますか？
                </button>
              </h2>
              <div
                id="collapsenine"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingnine"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "申し訳ございませんが、コメント作成の代行はしておりません。<br/>
                   また、原則お客様ご自身でのご入力をお願いしております。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingten">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseten"
                  aria-expanded="false"
                  aria-controls="collapseten"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  写真やコメントを修正したいのですがどうすればいいですか？
                </button>
              </h2>
              <div
                id="collapseten"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingten"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "まずはマイページ内のメッセージより、どちらの商品を修正したいかご連絡ください。<br/>
                   担当より修正方法についてご連絡いたします。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingeleven">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseeleven"
                  aria-expanded="false"
                  aria-controls="collapseeleven"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  一般社団法人音楽特定利用促進機構（ISUM）に登録のない曲を使いたいのですが
                </button>
              </h2>
              <div
                id="collapseeleven"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingeleven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "弊社では一般社団法人音楽特定利用促進機構（ISUM）に登録されている市販曲のみ対応しております。<br/>
                  大変申し訳ありませんが、現状ご希望の楽曲をムービーへ使用することはできかねます。<br/>
                  ご希望の楽曲がISUMへ登録されるよう、ISUMへお客様より直接リクエストを送ることができます。<br/>
                  ただし登録に1～2ヶ月ほど時間がかかり、場合によっては著作権等の関係で登録できないこともございます。<br/>ご了承ください。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingtwelve">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsetwelve"
                  aria-expanded="false"
                  aria-controls="collapsetwelve"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  会場からアスペクト比4：3のムービーと指示されたのですが
                </button>
              </h2>
              <div
                id="collapsetwelve"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingtwelve"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "弊社は、スクリーンだけでなくデジタルテレビやパソコンなどあらゆる上映シーンを考慮して、アスペクト比16:9で商品開発を行っております。<br/>

                  会場設備やプロジェクターの設定にもよりますが、4:3のスクリーンでも16:9のムービーを上映することができます。<br/>
                  お申込み後に商品のサンプルムービーを収録した「上映確認用ディスク」をお送りしておりますので、一度上映される会場にて再生をお試しいただき、16:9のムービーの上映について会場様へご相談ください。<br/>
                  もし万が一、再生を試しても表示が上手くいかない場合や会場様からお断りされた場合などには、弊社より対応方法をご提案いたしますので一度ご相談ください。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingthirteen">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsethirteen"
                  aria-expanded="false"
                  aria-controls="collapsethirteen"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  データ化した写真がありません。<br/>郵送するのでデータ化してもらえますか？
                </button>
              </h2>
              <div
                id="collapsethirteen"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingthirteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  "申し訳ございませんが、データ化の代行はしておりません。<br/>
                  お客様ご自身でのスキャンもしくはカメラ撮影をお願いしております。<br/>カメラ撮影をされる際は、影や光の映り込みにご注意くださいませ。"<br/>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3 shadow">
              <h2 className="accordion-header" id="headingfourteen">
                <button
                  className="accordion-button collapsed   text-dark bg-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefourteen"
                  aria-expanded="false"
                  aria-controls="collapsefourteen"
                >
                  <i className="fa-solid fa-q me-3 DamionFont fa-lg"></i>{" "}
                  お問い合わせをしたのですが、返信がありません。<br/>
                </button>
              </h2>
              <div
                id="collapsefourteen"
               className="accordion-collapse collapse bg-light "
                aria-labelledby="headingfourteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body d-flex text-success">
                <span className="fw-bold DamionFont fs-5 ">A.</span> 
                  ""お問い合わせに対するご返信は、2営業日以内にお返事いたしますので少々お待ちいただくようお願いいたします。<br/> 
                     2営業日を過ぎても返信がない場合は、定休日を挟んでいないか、パソコンからのメールを拒否設定にしていないか、迷惑メールに分類されていないか などをご確認下さい。<br/>
                     また、お問い合わせの際にお知らせ頂いたメールアドレスが間違っている場合はご返信することができませんので、再度必ずご連絡のつくアドレスでお問い合わせくださいますようお願いいたします。<br/>
                     フリーメールのアドレスや各キャリアメール(au、softbankなど）は、
                     迷惑メールに入ってしまったり、受信されないなどトラブルが起こる事がございますので、ご注意くださいませ。"<br/>"
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
}


export default Question;

