import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link,useNavigate } from 'react-router-dom';
import API, { path } from "../helper/Api";
interface Props {
  // history: any;
}
const Forgotpassword = (Props: Props) => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("")
 
  useEffect(() => {
    return () => { };
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const submitLogin = () => {
    API.post(`user/forgot/password`, {
     
        email: email,
       
     
    })
        .then((data) => {
            if (data.data.status) {               
                toast(data.data.message, { type: 'success' });
                setTimeout(() => {
                  navigate("/", { replace: false });
                }, 2000);
              
            } else {
                toast("しばらくしてから再度、お試しください", { type: 'error' });           
            }
        })
        .catch((error) => {
            console.log(error);
            
            if (error.response) {
                console.log("52");
                if (error.response.data.valData) {
                    let dataerrer = error.response.data.valData.errors.map(
                        (item: any, index: number) => {
                            return { name: item.param, message: item.msg };
                        }
                    );
                    for (let index = 0; index < dataerrer.length; index++) {
                        const element = dataerrer[index];
                        toast(element.message, { type: 'error' });
                        console.log("5sdsdf2");
                    }
                } else {
                    console.log("451");
                    toast(error.response.data.message, { type: 'error' });                        
                }
            } else {
                console.log("41");
            }
            console.log("error,error", error.response);

        });
}
  return (
    <>
     <ToastContainer />
      <HeaderNoBg></HeaderNoBg>

      <div className="container">
        <div className="row text-center">
          <Link className=" " to={'/'}> <img src="/img/logo.jpeg" className="mx-auto d-block mt-4" height={51} width={189} alt="" />  </Link>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container text-center">
          <div className="row ">
            <div className=" col-12 col-sm-2 col-md-2"></div>
            <div className=" col-12 col-sm-8 col-md-8 py-5 ">
              <form className=" py-5 border border-dark rounded  px-5">

                <h3 className="mb-5">新しいパスワードを入力してください</h3>
                <div className="row mb-3">
                  <label htmlFor="inputmailid" className="col-sm-4 col-form-label text-lg-start fw-semibold">
                  メールアドレスを入れてください</label>
                  <div className="col-sm-8">
                    <input type="email" className="form-control " id="inputEmail3" style={{ background: "#EFEFEF" }} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                  </div>
                </div>

                <button type="button" onClick={()=>{
                  submitLogin()
                }} className="btn btn-outline-success btn-lg mx-auto d-block px-5 mt-5 mb-3 ">パスワードを更新する</button>
              </form>
            </div>
            <div className="col-12 col-sm-2 col-md-2"></div>
          </div>

        </div>
      </div>

      <Footer></Footer>
    </>
  );
};
export default Forgotpassword;
