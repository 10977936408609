import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import { colors } from "@material-ui/core";
interface Props {
  // history: any;
}
const Ragisterinfo = (Props: Props) => {
  const [nameFirst, setNameFirst] = useState("");
  const [lastName, setLastName] = useState("")
  const [addressline1, setaddressline1] = useState("")
  const [address, setAddress] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  useEffect(() => {
    fetchPlans();
    return () => { };
  }, []);
  const fetchPlans = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.get(`user/me`, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {
        console.log("patch paln", data.data);
        if (data.data.status) {
          setNameFirst(data.data.data.first_name)
          setLastName(data.data.data.last_name)
          setaddressline1(data.data.data.address_line_1)
          setAddress(data.data.data.address_line_2)
          setPhoneNo(data.data.data.mobile)

        } else {
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const updateFile = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.patch(
      `user/signup/update/profile`,
      { mobile:phoneNo , first_name:nameFirst, last_name:lastName, address_line_1:addressline1,address_line_2:address },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (data.data.status) {
          toast(data.data.message, { type: 'success' });
          fetchPlans();
        } else {
          toast("しばらくしてから再度、お試しください", { type: 'error' });           
        }
      })
      .catch((error) => {
        console.log(error);
                
        if (error.response) {
            console.log("52");
            if (error.response.data.valData) {
                let dataerrer = error.response.data.valData.errors.map(
                    (item: any, index: number) => {
                        return { name: item.param, message: item.msg };
                    }
                );
                for (let index = 0; index < dataerrer.length; index++) {
                    const element = dataerrer[index];
                    toast(element.message, { type: 'error' });
                    console.log("5sdsdf2");
                }
            } else {
                console.log("451");
                toast(error.response.data.message, { type: 'error' });                        
            }
        } else {
            console.log("41");
        }
        console.log("error,error", error.response);
      });
  };
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
    <ToastContainer />
    <HeaderNoBg></HeaderNoBg>

      <div className="container-fulid py-5 my-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-3 border-end  border-dark reg-btn-1" style={{ height: 413 }}>
              <ul className="mt-2 Registerpage-ul">
             
              <Link to={"/password"}> <a className="text-dark">
                <li className="Registerpage-padd">・登録内容の確認・変更</li>
              </a></Link>
              <Link to={"/reset"}>    <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・パスワードの変更
                </li>
              </a></Link>

              <Link to={"/myorder"}> <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・入稿ページ
                </li>
              </a></Link>
         

              </ul>
            </div>
            <div className="col-md-9 px-4 ml-5 " style={{ fontSize: 15 }}>
              <div className="mypagecenter"><span className="fs-5   DamionFont" style={{ color: "#739654" }}>Registration information</span>
                <h4 className="ms-3">登録情報の確認・変更</h4>
              </div>
              <hr />
              <div className="row ms-2 margin-left-none  my-4" >
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>ファーストネーム</p>
                </div>
                <div className="col-md-8 col-sm-12">

                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="あなたの名を入力してください"
                    value={nameFirst}
                    onChange={(e) => {
                      setNameFirst(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="row ms-2 margin-left-none  my-4" >
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>苗字</p>
                </div>
                <div className="col-md-8 col-sm-12">

                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="家系の名前を入力してください"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="row my-4 ms-2 margin-left-none  ">
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>住所</p>
                </div>
                <div className="col-md-8 col-sm-12">
                  <div className="d-md-flex">
                    
                    <input
                      type="text"
                      className="form-control shadow-inner mb-4"
                      id="inputText"
                      placeholder="郵便番号"
                      value={addressline1}
                      onChange={(e) => {
                        setaddressline1(e.target.value)
                      }}
                    />
                  </div>
                  <div className="d-md-flex">
                   
                    <input
                      type="text"
                      className="form-control shadow-inner"
                      id="inputText"
                      placeholder="住所"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row ms-2 margin-left-none  ">
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>お電話番号</p>
                </div>
                <div className="col-md-8 col-sm-12">
                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="Please enter your mobile phone number"
                    value={phoneNo}
                    onChange={(e) => {
                      setPhoneNo(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row ms-2 margin-left-none  ">
            <div className="col-md-5 "></div>
            <div className="col-md-6 d-flex align-center">
              <button type="button" onClick={()=>{updateFile()}} className="btn btn-outline-danger rounded-0 mt-5 Registerpage-btn hidebutton-registration">編集する</button>
            </div>
          </div>
          <div className="row reg-btn-2">
          <ul className="mt-2 list-unstyled">
              <Link to={"/password"}> <a className="text-dark">
                <li className="Registerpage-padd">・登録内容の確認・変更</li>
              </a></Link>
              <Link to={"/reset"}>    <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・パスワードの変更
                </li>
              </a></Link>

              <Link to={"/myorder"}> <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・入稿ページ
                </li>
              </a></Link>
            </ul>

          </div>
        </div>
      </div>



      <Footer></Footer>
    </>
  );
};
export default Ragisterinfo;

