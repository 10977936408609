import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
interface Props {
  // history: any;
}
const ContactPage = (Props: Props) => {
  const [name, setname] = useState("")
  const [mobileNumbeer, setmobileNumbeer] = useState("")
  const [emailAddress, setemailAddress] = useState("")
  const [address, setAddress] = useState("")
  const [ConfirmAddress, setConfirmAddress] = useState("")
  const [tearm, setTearm] = useState(false)

  useEffect(() => {
    return () => { };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);

  }, [])
  const submitLogin = () => {
    API.post(`user/contact`, {
      name: name,
      email: emailAddress,
      phone: mobileNumbeer,
      address: address, message: ConfirmAddress,
      tearm:tearm?1:0
    })
      .then((data) => {
        if (data.data.status) {
          setname("")
          setmobileNumbeer("")
          setemailAddress("")
          setAddress("")
          setConfirmAddress("")
          setTearm(false)

          toast(data.data.message, { type: 'success' });

        } else {
          toast("しばらくしてから再度、お試しください", { type: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          console.log("52");
          if (error.response.data.valData) {
            let dataerrer = error.response.data.valData.errors.map(
              (item: any, index: number) => {
                return { name: item.param, message: item.msg };
              }
            );
            for (let index = 0; index < dataerrer.length; index++) {
              const element = dataerrer[index];
              toast(element.message, { type: 'error' });
              console.log("5sdsdf2");
            }
          } else {
            console.log("451");
            toast(error.response.data.message, { type: 'error' });
          }
        } else {
          console.log("41");
        }
        console.log("error,error", error.response);

      });
  }
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
     <ToastContainer />
      <HeaderNoBg></HeaderNoBg>
      <div className="container">
        <div className="container">
          <div className="row py-4 pb-4">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div>
                <span className="fs-5 DamionFont" style={{ color: "#739654" }}>
                  contact
                </span>
                <h2>お問い合わせ</h2>
                <hr></hr>
                <p>
                FOLKUSへのご相談、ご依頼などは下記フォームから送信できます。<br />
担当者より折り返しご連絡いたします。<br />
お問い合わせフォームをご利⽤の際は、必ず「個⼈情報保護⽅針」をご⼀読ください。<br />
内容に同意いただけましたら、下記フォームに必要事項をご⼊⼒の上、<br />
「送信」ボタンを押してください。<br />
                </p>
              </div>
              <div className=" py-5 row ">


                <label
                  htmlFor="inputName"
                  className="col-sm-3 col-form-label mt-4 semi"
                >
                  お名前 <span style={{ color: "#AC1B00" }}>必須</span>
                </label>
                <div className="col-sm-9 mt-4">
                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="名前を入力してください"
                    value={name} onChange={(e) => {
                      setname(e.target.value)
                    }}
                  />
                </div>

                <label
                  htmlFor="inputnumber"
                  className="col-sm-3 col-form-label mt-4 semi"
                >
                  電話番号(半角)
                </label>
                <div className="col-sm-9 mt-4">
                  <input
                    type="number"
                    className="form-control shadow-inner"
                    id="inputnumber"
                    placeholder="000-0000-0000"
                    value={mobileNumbeer} onChange={(e) => {
                      setmobileNumbeer(e.target.value)
                    }}
                  />
                </div>

                <label
                  htmlFor="exampleInputEmail1"
                  className="col-sm-3 col-form-label mt-4 semi"
                >
                  メールアドレス<span style={{ color: "#AC1B00" }}>必須</span>
                </label>
                <div className="col-sm-9 mt-4">
                  <input
                    type="email"
                    className="form-control shadow-inner"
                    id="exampleInputEmail1"
                    placeholder="info@XXXXXXX.XXX"
                    value={emailAddress} onChange={(e) => {
                      setemailAddress(e.target.value)
                    }}
                  />
                </div>



                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="col-sm-3 col-form-label mt-4 semi"
                >
                  住所
                </label>
                <div className="col-sm-9 mt-4">
                  <input
                    className="form-control shadow-inner"
                    id="exampleFormControlTextarea1"
                    placeholder="住所を入力してください"
                    value={address} onChange={(e) => {
                      setAddress(e.target.value)
                    }}
                  />
                </div>
                <label
                  htmlFor="inputPassword"
                  className="col-sm-3 col-form-label mt-4 semi"
                >
                 お問い合わせ内容<span style={{ color: "#AC1B00" }}>必須</span>
                </label>
                <div className="col-sm-9 mt-4 ">
                  <textarea
                    className="form-control py-5 shadow-inner"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    placeholder="お問い合わせ内容をご記入ください"
                    value={ConfirmAddress} onChange={(e) => {
                      setConfirmAddress(e.target.value)
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-"></div>
            </div>
          </div>

          <div className="container">
            <div className="row py-5 pb-3 ">
              <div className="col-md-1"></div>
              <div className="col-md-10 mb-3 ">

              <span>プライバシーポリシー</span> <br />
<span>当社の「個⼈情報保護⽅針」をお読みいただき、</span> <br />
<span>同意いただける場合は「プライバシーポリシーに同意」にチェックを入れて、「送信」を押して次へ進んでください。</span>

              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

          <div
            className="row col-sm-10 py-4 px-5 pb-3 border border-dark overflow-auto mx-auto d-block  "
            style={{ backgroundColor: "#ffff", height: 200 }}
          >
            プライバシーポリシー
            <br />
            1.個人情報保護に関する基本方針 br
            '
            株式会社キャリーズオウンランド（以下、当社）では、個人情報保護の重要性を認識し、以下の基本方針に基づき、当社が保有する個人情報の適正な管理と保護に努めます。 <br />
            • 当社は、個人情報保護のために、必要な方針、社内規程を定め、当社の従業員に周知徹底させ、取扱いを改善するよう努めます。<br />
            • 当社は、個人情報の保護に関する法令、本プライバシーポリシーを遵守いたします。<br />
            • 当社は、個人情報の漏洩、紛失、改ざんを防止するための、必要かつ合理的な安全対策を定めこれらを実行してまいるよう努めます。<br />
            • 当社は、個人情報の取得にあたりましては、適法かつ公正な手段によって行い、不正な方法によっては取得いたしません。また、個人情報をいただく場合には利用目的等について同意をいただくか、もしくは通知、公表いたします。<br />
            • 当社は、個人情報について開示、訂正、利用停止等のお申し出をいただいた場合には、誠実に対応させていただきます。<br />
            • 当社は、ご本人の同意を得ず個人情報を業務委託先、提携先以外の第三者に提供いたしません。 第三者との間で共同利用する場合や業務を委託するために個人情報を第三者に預託する場合には、個人情報保護に関する取り決めを行い個人情報保護に万全をつくすよう努めます。<br />
            2.個人情報取り扱い事クライアントの名称 <br />
            株式会社キャリーズオウンランド <br />
            3.個人情報の取得 <br />
            当社は当社のサービスを提供させていただくに際し、事業遂行上必要となる個人情報を取得します。個人情報を取得する場合には、その都度、利用目的を明示します。 <br />
            4.個人情報の利用目的 <br />
            ①ご契約いただいきました内容の履行と、下記の主たる事業（取引先、関係会社等、および委託業社、提携業社を含みます。）に付随するサービスをご提供するために必要な範囲内で利用させていただきます。<br />
            ②当社の事業活動に付随するマーケティング活動および市場動向等の分析に、お客様の個人情報を利用させていただきます。<br />
            5.個人情報の共同利用 <br />
            当社は上記4.に記載の目的範囲内で、個人情報やサービスに関する情報の必要な項目について当社の関係会社および、委託業社、提携先業社の間で共同利用、または委託先へ提供させていただくことがあります。<br />
            6.個人情報の第三者提供 <br />
            個人情報は次の場合を除いて、第三者に開示、提供することはありません。（共同利用は上記5.をご参照下さい。）<br />
          </div>

          <div className="row py-5">
            <div className="col-md-12">
              <div className="form-check d-flex justify-content-center my-5">
                <input
                  className="form-check-input mx-2 rounded-0"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={tearm}
                  defaultChecked={tearm}
                  onChange={(e) => {
                    setTearm(!tearm);
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  プライバシーポリシーに同意します。
                </label>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center ">
              <button
                type="button"
                onClick={() => { submitLogin() }}
                className="btn btn-lg btn-outline-danger rounded-0 px-5"

              >
               送信
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};
export default ContactPage;
