import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, useEffect } from "react";
import logo from './logo.svg';
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AccountInfo from "./pages/AccountInfo";
import AddCart from "./pages/AddCart";
import Contact from "./pages/Contact";
import Flow from "./pages/Flow";
import Profile from "./pages/Profile";
import Upload from "./pages/Upload";
import Movies from "./pages/Movies";
import FullPack from "./pages/FullPack";
import Password from "./pages/Password";
import Mypage from "./pages/Mypage";
import Ragisterinfo from "./pages/Ragisterinfo";
import Address from "./pages/Address";

import Myorder from "./pages/MyOrder";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/Forgotpassword";
import Updatepassword from "./pages/Updatepassword";

import './App.css';
import { connect } from "react-redux";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="account" element={<AccountInfo />} />  //have to remove this page 
        <Route path="cart" element={<AddCart />} />
        <Route path="contact" element={<Contact />} />
        <Route path="flow" element={<Flow />} />    
        <Route path="profile/:id" element={<Profile />} />    
        <Route path="upload/:id/:qty" element={<Upload />} />    
        <Route path="fullpack/:id" element={<FullPack />} />    
        <Route path="movies/:id" element={<Movies />} />   
        <Route path="password" element={<Password />} />
        <Route path="mypage" element={< Mypage />} />
        <Route path="ragisterinfo" element={<Ragisterinfo/>} />
        <Route path="address" element={<Address />} />
        <Route path="myorder" element={<Myorder />} />
        <Route path="reset" element={<ResetPassword />} />
        <Route path="forgot" element={<ForgotPassword />} />  
        <Route path="updatepassword/:id" element={<Updatepassword />} />  
      </Routes>
    </BrowserRouter>
  );
}

export default App;
