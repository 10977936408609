import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import API, { path } from "../helper/Api";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { FetchLogin } from "../reducers/user/actionCreator";
interface Props {
    FetchLogin?: any;
}
const RegisterPage = (Props: Props) => {
    let navigate = useNavigate();   
    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [addressLine1, setaddressLine1] = useState("")
    const [addressLine2, setaddressLine2] = useState("")
    const [phoneNumber, setphoneNumber] = useState("")
    const [email, setemail] = useState("")
    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")
    useEffect(() => {
        return () => { };
    }, []);
    const submitRegistration = () => {
        API.post(`user/signup`, {
            first_name: firstName,
            last_name: lastName,
            username: userName,
            mobile: phoneNumber,
            email: email,
            password: password,
            cnf_password: confirmPassword,
            address_line_1: addressLine1,
            address_line_2: addressLine2
        })
            .then((data) => {
                if (data.data.status) {
                    localStorage.setItem("@tocken", data.data.tocken);
                    Props.FetchLogin();
                    toast(data.data.message, { type: 'error' });
                    navigate("/", { replace: false });
                } else {
                    toast("しばらくしてから再度、お試しください", { type: 'error' });           
                }
            })
            .catch((error) => {
                console.log(error);
                
                if (error.response) {
                    console.log("52");
                    if (error.response.data.valData) {
                        let dataerrer = error.response.data.valData.errors.map(
                            (item: any, index: number) => {
                                return { name: item.param, message: item.msg };
                            }
                        );
                        for (let index = 0; index < dataerrer.length; index++) {
                            const element = dataerrer[index];
                            toast(element.message, { type: 'error' });
                            console.log("5sdsdf2");
                        }
                    } else {
                        console.log("451");
                        toast(error.response.data.message, { type: 'error' });                        
                    }
                } else {
                    console.log("41");
                }
                console.log("error,error", error.response);

            });
    }
    useEffect(() => {
        return () => {};
      }, []);
      useEffect(() => {
        setTimeout(() => {
          scrollToTop()
        }, 300);
       
      }, [])
      const scrollToTop = () =>{ 
      
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    return (
        <>
            <ToastContainer />
            <HeaderNoBg></HeaderNoBg>
            <div className="container-fluid py-5" style={{ backgroundColor: "#F8F8F8" }}>
                <form className="container ">
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                            <div>
                                <div className="row">
                                    <span className="fs-5 DamionFont" style={{ color: "#739654" }}>Register</span>
                                    <div className="col">
                                        <span style={{fontSize:25}}>新規登録</span>
                                    </div>
                                    <div className="col">
                                        <span style={{fontSize:15}}>お名前や住所、お電話番号を登録してください。</span>
                                    </div>
                                </div>
                            </div>
                            <hr className="hr1" style={{ backgroundColor: "black" }} />
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="">
                        <div className="row mb-3 mt-5 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputName" className="col-sm-2 col-form-label fs-5 ">お名前 &nbsp;<span className="regster-span">必須</span></label>
                            <div className="col-sm-4">
                                <input type="name" className="form-control" id="inputName" required placeholder="名前を入力してください" value={firstName} onChange={(e) => {
                                    setfirstName(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label font ">
                                お名前(全角カナ)&nbsp;<span className="regster-span">必須</span></label>
                            <div className="col-sm-4">
                                <input type="email" className="form-control" id="inputEmail3" required placeholder="名前を入力してください" value={lastName} onChange={(e) => {
                                    setlastName(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputAddress" className="col-sm-2 col-form-label font ">住所&nbsp;<span className="regster-span">必須</span></label>
                            <div className="col-sm-4">
                                <span className="font ">郵便番号</span>
                                <input type="Text" className="form-control" id="inputAddress" placeholder="郵便番号を入力してください" required value={addressLine1} onChange={(e) => {
                                    setaddressLine1(e.target.value)
                                }} />
                                <span className="font my-1 ">住所</span>
                                <input type="text" className="form-control mt-2" id="inputAddress2" placeholder="住所を入力してください" required value={addressLine2} onChange={(e) => {
                                    setaddressLine2(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputphonenumber" className="col-sm-2 col-form-label font ">
                                お電話番号 &nbsp;<span className="regster-span">必須</span></label>
                            <div className="col-sm-4">
                                <input type="number" className="form-control" id="inputEmail3" required placeholder="電話番号を入れしてください" value={phoneNumber} onChange={(e) => {
                                    setphoneNumber(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <form className="container-fluid py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                                <div>
                                    <div className="row">
                                        <span className=" fs-5 DamionFont" style={{ color: "#739654" }}>Accoount info</span>
                                        <div className="col-sm-6">
                                            <span style={{fontSize:25}}>アカウント情報</span>
                                        </div>
                                        <div className="col-sm-6">
                                            <span  style={{fontSize:15}}>ユーザー情報を登録してください。
                                                ログインする際に必要となりますので
                                                お忘れのないよう控えをお取りください。</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className="hr1" style={{ backgroundColor: "black" }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label font ">
                                メールアドレス &nbsp;<span className="regster-span">必須</span></label>
                            <div className="col-sm-4">
                                <input type="email" className="form-control" id="inputEmail3" required placeholder="メールアドレスを入力してください" value={email} onChange={(e) => {
                                    setemail(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 mt-5 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputId" className="col-sm-2 col-form-label font ">ユーザーID &nbsp;<span className="regster-span">必須</span> <br />半角英数字</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="inputName" required placeholder="ユーザーIDを入力してください" value={userName} onChange={(e) => {
                                    setuserName(e.target.value)
                                }} />
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 mt-5 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label font ">パスワード &nbsp;<span className="regster-span">必須</span> <br />半角英数字</label>
                            <div className="col-sm-4">
                                <input type="password" className="form-control" id="inputName" required placeholder="パスワードを入力してください" value={password} onChange={(e) => {
                                    setpassword(e.target.value)
                                }} />
                                <span className="fw-light" style={{fontSize:14}}>※ご希望のユーザーIDを入力してください。
                                    (４文字以上の半角英数字、ハイフン、アンダースコアも
                                    ご利用いただけます)</span>
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                        <div className="row mb-3 mt-5 req">
                            <div className="col-sm-3"></div>
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label font ">パスワード再入力 &nbsp;<span className="regster-span">必須</span> <br />半角英数字</label>
                            <div className="col-sm-4">
                                <input type="password" className="form-control" id="inputName" required placeholder="パスワードを再入力してください" value={confirmPassword} onChange={(e) => {
                                    setconfirmPassword(e.target.value)
                                }} />
                                <span className="fw-lighter"  style={{fontSize:14}}>※ご希望のユーザーIDを入力してください。
                                    (４文字以上の半角英数字、ハイフン、アンダースコアも
                                    ご利用いただけます)</span>
                            </div>
                            <div className="col-sm-1"></div>
                        </div>
                       <button type="button" onClick={() => { submitRegistration() }} className="btn btn-outline-danger btn-lg mx-auto d-block my-5 px-5 rounded-0 w-20">ユーザー登録する</button>
                    </div>
                </form>
            </div>
            <Footer></Footer>
        </>);
}
const mapStateToProps = (state: any) => ({
    //...state,
    is_login: state.user.is_login,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    FetchLogin: () => dispatch(FetchLogin()),
    // stopAction: () => dispatch(stopAction)
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
