import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom'
import { FetchLogin } from "../reducers/user/actionCreator";
interface Props {
  title:String;
  img :any;
}

const Flowbutton = (props: Props) => {
 


  return (
  
  <div className="container-fluid my-5 " >
  <div className="container  ">
    <div className="row pb-3 " style={{justifyContent:"center"}}>
    <img
          src={props.img}
          className="img-disg float-start bottom-image"
          alt=""
        />
      <div className="col-md-12 text-white text-light border border-light  rounded  py-2" style={{width:700,textDecoration: "none"}}>
         
         
          <div
            className="border rounded-2 text-center py-3"
            style={{
              backgroundImage:
                "linear-gradient(to Right,	#228B22 ,#32CD32)",
            }}
          > 
            <p className="font-size-1">{props.title} </p>
            <p className="font-size-1 ">お見積もり依頼はコチラをクリック</p>
            <div className="position-absolute d-inline-flex mx-5">
                        <img src="/img/arrow.png" alt="" className="arrow2"/>
                        </div>
          </div>
         
        </div>
  
    
    </div>
  </div>
  </div>
   
    );
}


export default Flowbutton;

