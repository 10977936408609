import React, {
  useEffect,
  useState,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FetchLogin, Fetchplan } from "../reducers/user/actionCreator";

interface Props {
  //  (props: PropsWithChildren<P>, context?: any): ReactElement<any, any> | null;
  classnamebg?: string | undefined;
  classnameimg?: string | undefined;
  children?: ReactNode;
  title?: string;
  japneestitle?: string;
  is_login?: boolean;
  plans?: Array<any>;
  FetchLogin?: any;
  Fetchplan?: any;
}

const HeaderPack: FC<Props> = ({
  classnamebg,
  classnameimg,
  title,
  japneestitle,
  is_login,
  FetchLogin,
  Fetchplan,
  plans,
}) => {
  const [toggler, setToggler] = useState(false);
  const [White, setWhite] = useState(false);
  const bgwhite = () => {
    console.log("hyy black");
    setToggler(!toggler);
    setWhite(!White);
  };

  useEffect(() => {
    Fetchplan();
    FetchLogin();
    return () => { };
  }, []);

  return (
    <div className="container-fluid m-0 p-0 ">
      {" "}
      <div className={` bg-white ${classnamebg ? classnamebg : ""} ${classnameimg ? classnameimg : ""}`}>

        <nav
          className={`navbar navbar-expand-lg navbar-light bg-white topnav mx-0 p-2 m-0 ${White ? "backWhite" : "backgTrans"
            }`}
        >
          <div className="container-fluid" style={{ zIndex: 5 }}>
            <Link className="navbar-brand mx-auto" to={"/"}>
              {" "}
              <img
                src="/img/logo.jpeg"
                className="navbarlogomobile"
                height={51}
                width={189}
                alt=""
              />{" "}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={bgwhite}
            >
              <span
                className={`border-0  ${toggler ? "fa fa-times" : "navbar-toggler-icon"
                  }`}
              ></span>
            </button>
            <div
              className="collapse navbar-collapse d-md-flex justify-content-md-end navfont"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto mb-2 mb-lg-0 fw-semibold text-center">
                {plans?.map((item, index) => {
                  return <li className="nav-item " style={{ textDecoration: "none" }}>
                    <Link key={index} to={`/profile/${item.id}`} className="nav-link active text-dark p-3 ">
                      <u className="navfont" style={{ textUnderlineOffset: 3 }}>{item.japness_title} </u> </Link>
                  </li>
                })}

                <li className="nav-item">
                  <a className="nav-link text-dark p-3 " href="/#planlink"><u className="navfont" style={{ textUnderlineOffset: 3 }}>その他ムービー</u></a>
                </li>

                <li className="nav-item">
                  <a className="nav-link text-dark p-3" href="/#questiondetails"><u className="navfont" style={{ textUnderlineOffset: 3 }}>よくある質問</u></a>
                </li>
                <li className="nav-item">
                  <Link to={"/contact"} className="nav-link   navfont" ><button className="btn btn-warning fw-semibold  navfont navbutton mt-1 shadow border-light" style={{ color: "#274D07", backgroundColor: "#B3D992", padding: 5 }} type="submit">お問い合わせ</button></Link>
                </li>
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link   navfont" ><button className="btn fw-semibold  navfont navbutton mt-1 shadow border-light border-5" style={{ color: "#FFFFFF", backgroundColor: "#274D07", padding: 5 }} type="submit">ログイン</button> </Link>
                </li>
                {is_login &&
                  <li className="nav-item">
                    <Link to={"/mypage"}><i className="fa fa-user py-3 mt-1 px-2" style={{ color: "#626262" }} aria-hidden="true"></i></Link>
                  </li>}
                {is_login &&
                  <li className="nav-item">
                    <Link to={"/cart"}><i className="fa fa-shopping-cart mt-1 py-3 px-2" style={{ color: "#626262" }} aria-hidden="true"></i></Link>
                  </li>}
              </ul>
            </div>
          </div>
        </nav>

        {classnamebg && classnamebg == "bg-profile" && (
          <div className="semi-cirlce-pfoflow">
            <h6 className="semicircle-text DamionFont">{title}</h6>
            <h4 className="semicircle-text-2">{japneestitle}</h4>
          </div>
        )}

        {/* {classnamebg && classnamebg == "bg-profile" && (
          <Link to={"/Contact"}>
            <div>
              <img src="/img/banner.png" className="right-box " alt="" />
            </div>{" "}
          </Link>
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  is_login: state.user.is_login,
  first_name: state.user.first_name,
  last_name: state.user.last_name,
  plans: state.user.plans,
});
const mapDispatchToProps = (dispatch: any) => ({
  FetchLogin: () => dispatch(FetchLogin()),
  Fetchplan: () => dispatch(Fetchplan()),
  // stopAction: () => dispatch(stopAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPack);
