import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import HeaderPack from "../components/HeaderPack";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { colors } from "@material-ui/core";
import Question from "../components/Question";
import Flowbuttontwo from "../components/Flowbuttontwo";
import { useNavigate } from "react-router-dom";
interface Props {
  // history: any;
}
const FullPackPage = (Props: Props) => {
  const [plans, setplans] = useState([]);
  let navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    fetchPlans();
    return () => { };
  }, []);
  const fetchPlans = () => {
    API.get(`plan/getall/active`)
      .then((data) => {
        console.log("patch paln", data.data);

        if (data.data.status) {
          setplans(data.data.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToTop();
    }, 300);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <HeaderPack
        classnamebg="bg-profile"
        classnameimg={
          `  ${id == "1" ? "  bg-profile1 " : ""} ${id == "2" ? "bg-profile bg-profile2" : ""
          }${id == "3" ? "bg-profile bg-profile3" : ""} bg-profile`
        }


        title={`${id == "1" ? "Full order" : ""} ${id == "2" ? "location shooting" : ""
          }${id == "3" ? "promotion movie" : ""}`}
        japneestitle={`${id == "1" ? "フルオーダー" : ""}${id == "2" ? "ロケーション撮影" : ""
          }${id == "3" ? "プロモーションムービー" : ""}`}
      ></HeaderPack>

      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row border-bottom border-4 py-4">
            {id == "1" && (
              <p className="" style={{ fontSize: 24 }}>
                お見積り【納期：内容により相談】{" "}
              </p>
            )}
            {id == "2" && (
              <p className="" style={{ fontSize: 24 }}>
                お見積もり【納期：内容により相談】{" "}
              </p>
            )}
            {id == "3" && (
              <p className="" style={{ fontSize: 24 }}>
                お見積もり【納期：内容により相談】{" "}
              </p>
            )}
          </div>
          {id == "1" && (
            <div className="row py-5 border-bottom border-4">
              <p style={{ fontSize: 18 }} className="">
                おふたりのイメージをフルオーダーで形にします。
              </p>

              <p style={{ fontSize: 18 }} className="mb-5">
                各種テンプレートよりさらに細かくご指定いただけます。
              </p>

              <p style={{ fontSize: 18 }} className="">
                各種SNS風にしたい」「思い出のモチーフや土地をテーマにしたい」
              </p>

              <p style={{ fontSize: 18 }} className="">
                など、お客様のご希望をヒアリングした上で、
              </p>

              <p style={{ fontSize: 18 }} className="">
                0からムービーを作らせていただくプランです。{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                弊社用意のテンプレートと比べて、お客様のこだわりを反映しやすく{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                お選びいただいた写真や楽曲と親和性の高いムービーとなりますので、
              </p>

              <p style={{ fontSize: 18 }} className="mb-5">
                よりイメージに近い、自由度の高い作品を作ることができます。
              </p>

              <p style={{ fontSize: 18 }} className="">
                結婚式用オープニングムービー、プロフィールムービー、エンドロールムービー等{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                ご希望の用途に合わせてお見積もりいたします。
              </p>
            </div>
          )}
          {id == "2" && (
            <div className="row py-5 border-bottom border-4">
              <p style={{ fontSize: 18 }} className="">
                おふたりが主役のショートムービーを、撮影からお手伝いします。
              </p>

              <p style={{ fontSize: 18 }}>
                思い出の場所や映画のような素敵なロケーションにて{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                弊社撮影班同行で、写真・動画撮影からご一緒させていただきます。
              </p>

              <p style={{ fontSize: 18 }} className="">
                ムービーの為におふたりを、動画イメージに合わせてお撮りしますので
              </p>

              <p style={{ fontSize: 18 }} className="">
                理想のムービーに仕上げることができます。{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                映画のようなドラマティックな映像から{" "}
              </p>

              <p style={{ fontSize: 18 }} className="">
                ミュージックビデオのようにスタイリッシュなムービーまで
              </p>

              <p style={{ fontSize: 18 }}>
                おふたりの夢を最大限形にすることのできるプランです。
              </p>
            </div>
          )}
          {id == "3" && (
            <div className="row py-5 border-bottom border-4">
              <p style={{ fontSize: 18 }} className="">
                動画はテキストや画像に比べて5,000倍の情報を持つといわれており、
              </p>

              <p style={{ fontSize: 18 }}>
                画像は視覚的な情報のみであるのに対して、
              </p>

              <p style={{ fontSize: 18 }} className="">
                動画は聴覚的な情報や動きの情報も持っているため、
              </p>

              <p style={{ fontSize: 18 }} className="">
                細かいニュアンスを伝えることも可能です。
              </p>

              <p style={{ fontSize: 18 }} className="">
                ■ブランディング動画
              </p>

              <p style={{ fontSize: 18 }} className="">
                ■企業紹介動画
              </p>

              <p style={{ fontSize: 18 }} className="">
                ■商品・サービスの紹介動画
              </p>

              <p style={{ fontSize: 18 }}>■ハウツー動画</p>
              <p style={{ fontSize: 18 }}>■SNS用ショートムービー</p>
              <p style={{ fontSize: 18 }}>■ミュージックビデオ</p>
              <p style={{ fontSize: 18 }}>
                などなど、多岐にわたりますのでお問い合わせください。{" "}
              </p>
            </div>
          )}
        </div>
      </div>
      {id == "1" && (
        <Link to={"/contact"} className="text-decoration-none">
          <Flowbuttontwo
            title="フルオーダー映像の"
            img="/img/fullpack.png"
          ></Flowbuttontwo>
        </Link>
      )}
      {id == "2" && (
        <Link to={"/contact"} className="text-decoration-none">
          {" "}
          <Flowbuttontwo
            title="ロケ撮影の"
            img="/img/location.png"
          ></Flowbuttontwo>
        </Link>
      )}
      {id == "3" && (
        <Link to={"/contact"} className="text-decoration-none">
          {" "}
          <Flowbuttontwo
            title="プロモーションの"
            img="/img/corporate.png"
          ></Flowbuttontwo>
        </Link>
      )}

      {id == "1" && (
        <Link to={"/Contact"}>
          <div>
            <img src="/img/banner.png" className="right-box " alt="" />
          </div>{" "}
        </Link>
      )}
      {id == "2" && (
        <Link to={"/Contact"}>
          <div>
            <img src="/img/banner2.png" className="right-box " alt="" />
          </div>{" "}
        </Link>
      )}
      {id == "3" && (
        <Link to={"/Contact"}>
          <div>
            <img src="/img/banner3.png" className="right-box " alt="" />
          </div>{" "}
        </Link>
      )}


      <div className="container">

        <div className="row">
          <div className="row row-cols-1 row-cols-md-3 g-4 mobile_view">
            {plans.map((item: any, index: number) => {
              return (
                <div className="col" key={index}>
                  <div className="card  " style={{ border: "none" }}>
                    <span className="p-color DamionFont">{item.title}</span>
                    <span className="p-color-2 fw-bold mb-1">{item.japness_title}</span>
                    <iframe src={item.video_link} className="card-img-top   front_page_video2 mx-auto d-block" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    <Link to={`/profile/${item.id}`} className="text-decoration-none">
                      <div className="card-footer marginTop d-flex justify-content-between align-items-center p-color-2">
                        <small className="fs-5">
                          {" "}
                          Price
                          <b>
                            ¥{parseFloat(item.amount).toLocaleString("hi-IN")}~
                          </b>
                        </small>
                        <i className="fa-solid fa-angle-right "></i>
                      </div>

                    </Link>

                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>


      <Question></Question>
      <Footer></Footer>
    </>
  );
};
export default FullPackPage;
