import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import API, { path } from "../helper/Api";
import axios from "axios";
interface Props {
  // history: any;
}
const UploadPage = (Props: Props) => {
  let navigate = useNavigate();
  let { id, qty } = useParams();
  const myRef = useRef<Array<any>>([]);
  myRef.current = [];
  const [groomref, setGroomref] = useState([]);
  const [groom, setGroom] = useState<Array<any>>([]);
  const [bride, setBride] = useState<Array<any>>([]);
  const [tow_person, setTow_person] = useState<Array<any>>([]);
  let fileRef = [];
  useEffect(() => {
    fetchPlans();
    return () => { };
  }, []);
  const addToRefs: (el: any) => void = (el) => {
    if (el && !myRef.current.includes(el)) {
      myRef.current.push(el);
    }
  };
  const [progress, setProgress] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const [userorder, setUserorder] = useState<any>(undefined);
  const [, setstate] = useState({});
  useEffect(() => {
    return () => { };
  }, []);
  const myUploadProgress = (progress: any) => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total);
    setProgress(percentage);
    //this.setState({ progress: percentage })
  };
  const fileChange = (event: any, index: number, type: number) => {

    let exts = event.target.files[0].name.split(".")

    var _validFileExtensions = ["jpg", "jpeg", "png", "heic", "raw"];
    if (_validFileExtensions.indexOf(exts[exts.length - 1].toLowerCase()) === -1) {
      toast("有効な画像ファイルを入稿してください", {
        type: "error",
      });
      return;
    }
    setIsUpload(true);
    setProgress(0);
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    axios
      .patch(path() + "/api/order/file/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: myUploadProgress,
      })
      .then((data) => {
        if (type == 1) {
          let file = groom;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setGroom(file);
        } else if (type == 2) {
          let file = bride;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setBride(file);
        } else if (type == 3) {
          let file = tow_person;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setTow_person(file);
        }
        setstate({})
        console.log(data.data.name);
        setIsUpload(false);
        setProgress(0);
        toast("ファイルが入稿されました", {
          type: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateFile = (updateFile: boolean) => {
    console.log("{ groom, bride, tow_person, qty }", { groom, bride, tow_person, qty });

    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.patch(
      `order/update/order/file/${id}`,
      { groom, bride, tow_person, qty },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        if (updateFile) {
          updateComplete();
        } else {
          toast("ファイルが一時保存されました", {
            type: "success",
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);

        }

      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
        toast("しばらくしてから再度、お試しください", {
          type: "success",
        });
      });
  };
  const updateComplete = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.patch(
      `order/update/order/complete/${id}`,
      { qty },
      {
        headers: { Authorization: AuthStr },
      }
    )
      .then((data) => {
        console.log("patch paln", data.data);
        if (data.data.status) {
          toast("ファイルが保存されました", {
            type: "success",
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          toast("しばらくしてから再度、お試しください", {
            type: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
        toast("しばらくしてから再度、お試しください", {
          type: "success",
        });
      });
  };


  const fetchPlans = () => {
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.get(`order/get/user/orderdetails/${id}`, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {

        if (data.data.status) {
          setUserorder(data.data.data);
          let boy = data.data.data[0].plan_category[0].no_boy_photo;
          let girl = data.data.data[0].plan_category[0].no_boy_photo;
          let both = data.data.data[0].plan_category[0].no_both_photo;

          let itemgroom: Array<any> = [];


          for (let index = 0; index < boy; index++) {
            itemgroom.push({
              file: "",
              name: "",
              text: "",
            });

          }

          // setGroom(itemgroom);
          let itembride: Array<any> = [];

          for (let index = 0; index < girl; index++) {
            itembride.push({
              file: "",
              name: "",
              text: "",
            });

          }

          // setBride(itembride);
          let itemtwoperson: Array<any> = [];

          for (let index = 0; index < both; index++) {
            itemtwoperson.push({
              file: "",
              name: "",
              text: "",
            });
          }
          // setTow_person(itemtwoperson);
          setTimeout(() => {
            fetchDraftOrder(itemgroom, itembride, itemtwoperson);
          }, 1000);

        } else {
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const fetchDraftOrder = (itemgroom: Array<any>, itembride: Array<any>, itemtwoperson: Array<any>) => {
    setGroom(itemgroom);
    setBride(itembride);
    setTow_person(itemtwoperson);
    setstate({})
    let tocken = localStorage.getItem("@tocken");
    const AuthStr = "Bearer ".concat(tocken ? tocken : "");
    API.get(`order/get/user/draft/${id}/${qty}`, {
      headers: { Authorization: AuthStr },
    })
      .then((data) => {

        if (data.data.status) {
          console.log("fatchorder draft", data.data, itemgroom, itembride, itemtwoperson);
          let grromitems = itemgroom;
          let brideitems = itembride;
          let tow_personitems = itemtwoperson;
          console.log("log datat pre", tow_personitems, grromitems, brideitems);
          for (let index = 0; index < data.data.data.length; index++) {

            const element = data.data.data[index];

            if (element.type == "groom") {
              //console.log("log datat groom", element);
              for (let indexinner = 0; indexinner < grromitems.length; indexinner++) {
                const elementinner = grromitems[indexinner];
                if (elementinner.file.length == 0) {
                  elementinner.file = element.file;
                  elementinner.name = element.file_name;
                  elementinner.text = element.text;
                  grromitems[indexinner] = elementinner;
                  break;
                }
              }
            }
            if (element.type == "bridge") {
              console.log("log datat bridge", element, brideitems);
              for (let indexinner = 0; indexinner < brideitems.length; indexinner++) {
                console.log("log datat bridge indexinnerb", indexinner);
                const elementinner = brideitems[indexinner];
                console.log("log datat bridge", element, indexinner, elementinner);
                if (elementinner.file.length == 0) {
                  console.log("log datat bridge", element, elementinner);
                  elementinner.file = element.file;
                  elementinner.name = element.file_name;
                  elementinner.text = element.text;
                  brideitems[indexinner] = elementinner;
                  break;
                }
              }
            }
            if (element.type == "two_person") {
              // console.log("log datat two_person", element);
              for (let indexinner = 0; indexinner < tow_personitems.length; indexinner++) {
                const elementinner = tow_personitems[indexinner];
                if (elementinner.file.length == 0) {
                  elementinner.file = element.file;
                  elementinner.name = element.file_name;
                  elementinner.text = element.text;
                  tow_personitems[indexinner] = elementinner;
                  break;
                }
              }
            }
            //console.log("log datat element", tow_personitems, grromitems, brideitems);
          }
          //console.log("log datat end datat", tow_personitems, grromitems, brideitems);

          setGroom(grromitems);
          setBride(brideitems);
          setTow_person(tow_personitems);
          setstate({})
        } else {

        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };
  const onDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const onDragLeave = (event: any) => { };
  const onDrop = (event: any, index: number, type: number) => {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    let exts = files[0].name.split(".");
    var _validFileExtensions = ["jpg", "jpeg", "png", "heic", "raw"];
    if (_validFileExtensions.indexOf(exts[exts.length - 1].toLowerCase()) === -1) {
      toast("有効な画像ファイルを入稿してください", {
        type: "error",
      });
      return;
    }
    setIsUpload(true);
    setProgress(0);
    let formData = new FormData();
    formData.append("file", files[0]);
    axios
      .patch(path() + "/api/order/file/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: myUploadProgress,
      })
      .then((data) => {
        if (type == 1) {
          let file = groom;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setGroom(file);
        } else if (type == 2) {
          let file = bride;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setBride(file);
        } else if (type == 3) {
          let file = tow_person;
          file[index].file = data.data.name;
          file[index].name = data.data.mainfile;

          setTow_person(file);
        }
        setstate({})
        console.log(data.data.name);
        setIsUpload(false);
        setProgress(0);
        toast("ファイルが入稿されました", {
          type: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    return () => { };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);

  }, [])
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <ToastContainer />
      <HeaderNoBg></HeaderNoBg>
      <div className="container container-Full">
        <div className="row my-5">
          <div className="col-12  col-sm-12 col-md-8 ">
            <ul className="list-group rounded-4 " id="groom">
              <li
                className="list-group-item"
                style={{ backgroundColor: "rgb(193, 193, 193)" }}
              >
                <i
                  className="fa fa-square px-2"
                  style={{ color: "green" }}
                  aria-hidden="true"
                ></i>
                新郎様入稿データ {isUpload && progress}
              </li>
              {isUpload && <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-label="Example with label"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  25%
                </div>
              </div>}
              {groom.map((item: any, index: number) => {
                return (
                  <li
                    className="list-group-item "
                    style={{ backgroundColor: "rgb(225, 222, 222)" }}
                  >
                    {" "}
                    <span>No.{index + 1}</span>
                    <div className="row">

                      <div className="col-12 col-md-11">
                        <div
                          className="container-fluid"
                          onDragOver={onDragOver}
                          onDragLeave={onDragLeave}
                          onDrop={(e) => onDrop(e, index, 1)}
                        //onClick={this.openFileDialog}
                        >
                          <div
                            className="row bg-light py-3 text-center"
                            style={{ minHeight: 60, justifyContent: "center", cursor: "pointer" }}
                            onClick={() => {
                              myRef.current[index].click();
                            }}
                          >
                            ここにファイルをドラッグ&ドロップしてください。
                          </div>
                          <input
                            type="file" ref={addToRefs} onChange={(e) => fileChange(e, index, 1)} style={{ display: "none" }} className="form-control rounded-0" id="inputGroupFile01" />
                        </div>

                        <input
                          type="text"
                          name=""
                          id=""
                          className="my-2 w-100 py-1 border border-white 
                                        mx-auto d-block text-center"
                          placeholder="テキストを入力してください(25文字以内)"
                          value={item.text}
                          onChange={(e) => {
                            let file = groom;
                            file[index].text = e.target.value;
                            setGroom(file);
                            setstate({});
                          }}
                        />
                      </div>
                      <div
                        className=" col-12 col-md-1 bg-light"
                        style={{ height: 101, padding: 0 }}
                      >
                        {item.file.length > 0 && (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={item.file}
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {item.file.length > 0 && (
                          <button
                            type="button"
                            onClick={() => {
                              let file = groom;
                              file[index].file = "";
                              file[index].name = "";
                              setGroom(file);
                              setstate({});
                            }}
                            className="btn  btn-sm text-primary "
                          >
                            削除
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>{" "}
            <br />
            <ul className="list-group rounded-4 " id="bride">
              <li
                className="list-group-item"
                style={{ backgroundColor: "rgb(193, 193, 193)" }}
              >
                <i
                  className="fa fa-square px-2"
                  style={{ color: "pink" }}
                  aria-hidden="true"
                ></i>

                新婦様入稿データ
              </li>
              {isUpload && <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-label="Example with label"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  25%
                </div>
              </div>}
              {bride.map((item: any, index: number) => {
                return (
                  <li
                    className="list-group-item"
                    style={{ backgroundColor: "rgb(225, 222, 222)" }}
                  >
                    {" "}
                    <span>No.{index + groom.length + 1}</span>
                    <div className="row">

                      <div className="col-12 col-md-11">
                        <div
                          className="container-fluid"
                          onDragOver={onDragOver}
                          onDragLeave={onDragLeave}
                          onDrop={(e) => onDrop(e, index, 2)}
                        //onClick={this.openFileDialog}
                        >
                          <div
                            className="row bg-light py-3 text-center"
                            style={{ minHeight: 60, justifyContent: "center", cursor: "pointer" }}
                            onClick={() => {
                              myRef.current[(groom.length) + index].click();
                            }}
                          >
                            ここにファイルをドラッグ&ドロップしてください。
                          </div>
                          <input
                            type="file" ref={addToRefs} onChange={(e) => fileChange(e, index, 2)} style={{ display: "none" }} className="form-control rounded-0" id="inputGroupFile01" />
                        </div>

                        <input
                          type="text"
                          name=""
                          id=""
                          className="my-2 w-100 py-1 border border-white 
                                        mx-auto d-block text-center"
                          placeholder="テキストを入力してください(25文字以内)"
                          value={item.text}
                          onChange={(e) => {
                            let file = bride;
                            file[index].text = e.target.value;
                            setBride(file);
                            setstate({});
                          }}
                        />
                      </div>
                      <div
                        className=" col-12 col-md-1 bg-light"
                        style={{ height: 101, padding: 0 }}
                      >
                        {item.file.length > 0 && (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={ item.file}
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {item.file.length > 0 && (
                          <button
                            type="button"
                            onClick={() => {
                              let file = bride;
                              file[index].file = "";
                              file[index].name = "";
                              setBride(file);
                              setstate({});
                            }}
                            className="btn  btn-sm text-primary "
                          >
                            削除
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>{" "}
            <br />
            <ul className="list-group rounded-4 " id="couple">
              <li
                className="list-group-item"
                style={{ backgroundColor: "rgb(193, 193, 193)" }}
              >
                <i
                  className="fa fa-square px-2"
                  style={{ color: "orange" }}
                  aria-hidden="true"
                ></i>
                お二人入稿データ
              </li>
              {isUpload && <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-label="Example with label"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  25%
                </div>
              </div>}
              {tow_person.map((item: any, index: number) => {
                return (
                  <li
                    className="list-group-item"
                    style={{ backgroundColor: "rgb(225, 222, 222)" }}
                  >
                    {" "}
                    <span>No.{index + groom.length + bride.length + 1}</span>
                    <div className="row">

                      <div className="col-12 col-md-11">
                        <div
                          className="container-fluid"
                          onDragOver={onDragOver}
                          onDragLeave={onDragLeave}
                          onDrop={(e) => onDrop(e, index, 3)}
                        //onClick={this.openFileDialog}
                        >
                          <div
                            className="row bg-light py-3 text-center"
                            style={{ minHeight: 60, justifyContent: "center", cursor: "pointer" }}
                            onClick={() => {
                              console.log(myRef.current, (groom.length) + (bride.length) + index);

                              myRef.current[(groom.length) + (bride.length) + index].click();
                            }}
                          >
                            ここにファイルをドラッグ&ドロップしてください。
                          </div>
                          <input
                            type="file" ref={addToRefs} onChange={(e) => fileChange(e, index, 3)} style={{ display: "none" }} className="form-control rounded-0" id="inputGroupFile01" />
                        </div>

                        <input
                          type="text"
                          name=""
                          id=""
                          className="my-2 w-100 py-1 border border-white 
                                        mx-auto d-block text-center"
                          placeholder="テキストを入力してください(25文字以内)"
                          value={item.text}
                          onChange={(e) => {
                            let file = tow_person;
                            file[index].text = e.target.value;
                            setTow_person(file);
                            setstate({});
                          }}
                        />
                      </div>
                      <div
                        className=" col-12 col-md-1 bg-light"
                        style={{ height: 101, padding: 0 }}
                      >
                        {item.file.length > 0 && (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={item.file}
                          ></img>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {item.file.length > 0 && (
                          <button
                            type="button"
                            onClick={() => {
                              let file = tow_person;
                              file[index].file = "";
                              file[index].name = "";
                              setTow_person(file);
                              setstate({});
                            }}
                            className="btn  btn-sm text-primary "
                          >
                            削除
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col-2 col-sm-3 col-md-2 mb-5 sizeLineVideo fixedbox " >
            <div className="container sizeLineVideo  ">
              <div className="col buttons container-fixed" style={{backgroundColor:"white"}}>
                <div className="row ">
                  <a href="#groom">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-success btn-sm uploadbtn "
                    >
                      新郎様入稿データ
                    </button>
                  </a>
                </div>
                <div className="row ">
                  <a href="#bride">
                    <button
                      type="button"
                      className="btn text-light btn-sm uploadbtn my-2"
                      style={{ backgroundColor: "#FF90BF" }}
                    >
                      新婦様入稿データ
                    </button>{" "}
                  </a>
                </div>
                <div className="row ">
                  <a href="#couple">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-warning text-light btn-sm uploadbtn"
                    >
                      お二人入稿データ
                    </button>{" "}
                  </a>
                </div>
                <div className="row " style={{ marginLeft: 18 }}>
                  <hr
                    style={{ border: "1px solid black" }}
                    className="row my-3"
                  />
                </div>
                <div className="row  " style={{ marginLeft: 5 ,width: 197}}>

                  <button
                    type="button"
                    onClick={() => {
                      updateFile(false);
                    }}
                    className="btn  bg-white border border-danger btn-sm  text-danger  uploadbtn "
                    style={{ height: 46 }}
                  >
                    一時保存
                  </button>


                </div>
                <div className="row " style={{ marginLeft: 5,width: 197}}>

                  <button
                    type="button"
                    onClick={() => {
                      updateFile(true);
                    }}
                    className="btn bg-white border border-success text-success btn-sm uploadbtn"
                    style={{ height: 46 }}
                  >
                    完了
                  </button>


                </div>
                <div className="row sizeLineVideo   ">
                  <span className="fw-bold mt-4 ">Sample movie</span>
                  {userorder && (
                    <iframe
                      src={userorder[0].plan_category[0].upload_video_link?userorder[0].plan_category[0].upload_video_link:""}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-sm-12 col-md-2"></div>
        </div>
      </div>
      <Footer ></Footer>
    </>
  );
};
export default UploadPage;
