import React, { useEffect, useRef, useState } from "react";
import API, { path } from "../helper/Api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Question from "../components/Question";
import Flowbutton from "../components/Flowbutton";
import { capitalize } from "@material-ui/core";
interface Props {
  // history: any;
}
const HomePage = (Props: Props) => {
  const questionRef = useRef<any>();
  const planRef = useRef<any>();

  const [plans, setplans] = useState([]);
  function scrollToComponent() {
    console.log(
      "hash ", window.location.hash
    );
    if (window.location.hash === '#planlink') {
      setTimeout(() => {
        planRef.current?.scrollIntoView();
        planRef.current?.focus();
      }, 1500);

    }
    if (window.location.hash === '#questiondetails') {
      setTimeout(() => {
        questionRef.current?.scrollIntoView();
        questionRef.current?.focus();
      }, 1500);
    }
  }
  useEffect(() => {
    fetchPlans();
    scrollToComponent();
    return () => { };
  }, []);
  const fetchPlans = () => {
    API.get(`plan/getall/active`)
      .then((data) => {
        console.log("patch paln", data.data);

        if (data.data.status) {
          setplans(data.data.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);

        console.log("error,error", error.response);
      });
  };


  return (
    <>
      <Header classnamebg="bg-testing"></Header>
      <div className="container-fluid my-5 pb-5">
        <div className="contaniner">
          <div className="row">
            <div className="text-center">
              <p>
                {" "}
                |<br></br>SCROLL<br></br>|
              </p>
            </div>
          </div>
          <div className="row text-center">
            <a href="">
              <img src="img/deglog.png" className="img-height" width={298} alt="" />
            </a>
          </div>
          <div className="text-center" style={{ lineHeight: 0 }}>
            <h2 className="fw-bold first-heading" >
              自分で作る動画だから <br></br>
              <u className="wavy-line">
                {" "}
                <span style={{ color: "#739654" }}>一生の思い出</span> に残る
              </u>
            </h2>
          </div>
        </div>
      </div>
      <div
        className="container-fluid my-5 py-5 "
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <div className="container  ">
          <div
            className="row text-center py-4 text-light my-2"
            style={{ backgroundColor: "#5F8340" }}
          >
            <h4 className="fw-bold letterspacing">こんな映像が <span style={{ color: '#FFD800' }}>オリジナル</span> で作れます</h4>
          </div>
          <div
            className="row row-cols-1 row-cols-md-3 g-4 px-3"
            style={{ backgroundColor: "#739654" }}
          >
            {plans.map((item: any, index: number) => {
              return (
                <div className="col " key={index}>
                  <div className=" h-100">
                    <p className="card-text text-light fw-6 fontSize fw-semibold">{item.japness_title}</p>
                    <Link to={`/profile/${item.id}`} className="link-light">
                      {" "}

                      <iframe src={item.video_link} className="front_page_video mx-auto d-block" width="100%" height="20%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

                      <div className="card-body mt-2 mb-4">
                        <p className="text-end text-light text-uppercase">{item.title}</p>
                      </div>
                    </Link>
                  </div>
                </div>)
            })}

          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 ">
            <div className=" mx-2">
              <span className="underline-green  DamionFont mb-3">How to make</span> <br></br>
              <p className="underline-green1  DamionFont">your video</p>
              <p className="fw-bold">ムービー制作の流れ</p>
            </div>
            <div className="row my-5 ">
              <div>
                <img
                  src="img/Group61.png"
                  alt=""
                  className="img-thumbnail photo-left"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="row">
              <div className="col-md-10 col-8 ">
                <div className="extra1">
                  <div className="extra">
                    <p className="fw-bold p-col-no ">01&nbsp; </p>
                    <span
                      className="fw-semibold fontSize"
                      style={{ color: "#1b3603" }}
                    >
                      テンプレートを購入
                    </span>
                  </div>
                  <div className="line"></div>
                  <div
                    className="mt-3"
                    style={{ color: "black", letterSpacing: 2 }}
                  >
                    <p style={{ textAlign: "justify" }} >
                      お好みのテンプレートをお選びください。
                      数ある中からお2人だけの特別な映像を作りましょう！

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-4">
                <div className=" icon-bg  ">
                  <img src="img/signup.png" alt="" className="icon-style img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-8 text-justify ">
                <div className="extra1">
                  <div className="extra">
                    <p className="fw-bold p-col-no ">02&nbsp; </p>
                    <span
                      className="fw-semibold fontSize"
                      style={{ color: "#1b3603" }}
                    >
                      会員登録
                    </span>
                  </div>
                  <div className="line"></div>
                  <div
                    className="mt-3"
                    style={{ fontSize: 15, color: "black", letterSpacing: 2 }}
                  >
                    <p style={{ textAlign: "justify" }} >
                      会員登録は無料！
                      マイページからお2人の写真を入稿しましょう！

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-4">
                <div className=" icon-bg">
                  <img src="img/second.png" alt="" className="icon-style img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-8 ">
                <div className="extra1">
                  <div className="extra">
                    <p className="fw-bold p-col-no ">03&nbsp; </p>
                    <span
                      className="fw-semibold fontSize"
                      style={{ color: "#1b3603" }}
                    >
                      画像・テキストを入稿
                    </span>
                  </div>
                  <div className="line"></div>
                  <div
                    className="mt-3"
                    style={{ fontSize: 15, color: "black", letterSpacing: 2 }}
                  >
                    <p style={{ textAlign: "justify" }} >
                      これまでお2人が作ってきた思い出のお写真を
                      入稿しましょう！お好きなテキストも入れること
                      ができるので、お2人ならではの映像が完成！

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-4">
                <div className=" icon-bg">
                  <img src="img/movielogo.png" alt="" className="icon-style img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-8">
                <div className="extra1 ">
                  <div className="extra">
                    <p className="fw-bold p-col-no ">04&nbsp; </p>
                    <span
                      className="fw-semibold fontSize"
                      style={{ color: "#1b3603" }}
                    >
                      入稿
                    </span>
                  </div>
                  <div className="line"></div>
                  <div
                    className="mt-3"
                    style={{ fontSize: 15, color: "black", letterSpacing: 2 }}
                  >
                    <p style={{ textAlign: "justify" }} >
                      画像・テキストが決まれば、さぁ入稿！
                      修正や不明点などはサポートスタッフがいるので安心！

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-4">
                <div className=" icon-bg">
                  <img src="img/sidemovie.png" alt="" className="icon-style img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 col-8 ">
                <div className="extra1">
                  <div className="extra">
                    <p className="fw-bold p-col-no ">05&nbsp; </p>
                    <span
                      className="fw-semibold fontSize"
                      style={{ color: "#1b3603" }}
                    >
                      納品
                    </span>
                  </div>
                  <div className="line"></div>
                  <div
                    className="mt-3"
                    style={{ fontSize: 15, color: "black", letterSpacing: 2 }}
                  >
                    <p >
                      約1ヶ月で商品がお手元に届きます。
                      式場の用途に合わせた納品形態をご確認ください。

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-4">
                <div className="icon-bg">
                  <img src="img/moviecart5.png" alt="" className="icon-style img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row float-end ">
          <Link to={"/flow"}>
            {" "}
            <button
              className="btn btn-warning btn-sm px-3 fs-6 ml-2 right-btn mt-5"
              style={{ color: "#274D07", backgroundColor: "#B3D992" }}
              type="submit"
            >
              お問い合わせ
            </button>{" "}
          </Link>
        </div> */}
        <div className="row photo-right">
          <div>
            <img
              src="img/Group61.png"
              alt=""
              className="img-thumbnail photo-right"
            />
          </div>
        </div>
        <div className="row mt-5 ">
          <Link to={"/flow"}>
            {" "}
            <button
              className="btn  btn-sm px-3  ml-2 right-btn  mb-4 float-end shadow border-light border-5"
              style={{ color: "#274D07", backgroundColor: "#B3D992", width: 123, height: 31, fontSize: 15 }}
              type="submit"
            >
              詳しく見る
            </button>{" "}
          </Link>
          <div
            className="col-md-12 doted-line "
            style={{ color: "#739654" }}
          ></div>
        </div>
      </div>
      <div className="container-fluid" ref={planRef}>
        <div className="container">
          <div className="row text-center mt-5 ">
            <h2 className=" heading-plan-line DamionFont ">Plan</h2><br />
            <p className="fw-bold text-left" style={{ letterSpacing: 3 }}>プラン一覧</p>
          </div>
          <div className="row">
            <div className="row row-cols-1 row-cols-md-3 g-4 mobile_view">
              {plans.map((item: any, index: number) => {
                return (
                  <div className="col" key={index}>
                    <div className="card  " style={{ border: "none" }}>
                    <Link to={`/profile/${item.id}`} className="text-decoration-none">
                      <span className="p-color DamionFont">{item.title}</span>
                      <span className="p-color-2 fw-bold mb-1">{item.japness_title}</span>
                    </Link>
                      <iframe src={item.video_link} className="card-img-top   front_page_video2 mx-auto d-block" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                      <Link to={`/profile/${item.id}`} className="text-decoration-none">
                        <div className="card-footer marginTop d-flex justify-content-between align-items-center p-color-2">
                          <small className="fs-5">
                            {" "}
                            Price
                            <b>
                              ¥{parseFloat(item.amount).toLocaleString("hi-IN")}~
                            </b>
                          </small>
                          <i className="fa-solid fa-angle-right "></i>
                        </div>

                      </Link>

                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid" ref={planRef}>
        <div className="container">
          <div className="row">
            <div className="row row-cols-1 row-cols-md-3 g-4 pb-5 mobile_view">
              <div className="col">
                <div className="card h-100" style={{ border: "none" }}>
                  <span className="p-color DamionFont" id="planlink" >Full order</span>
                  <span className="p-color-2 fw-bold mb-1">フルオーダー</span>
                  <Link to={"/fullpack/1"}>
                    <img
                      src="img\fullorder.png"
                      className="card-img-top "
                      alt="..."
                    />
                  </Link>
                  {/* <div className="card-footer d-flex justify-content-between align-items-center p-color-2">
                    <small className="fs-5">
                      {" "}
                      Price<b>¥15,000~</b>
                    </small>
                    <i className="fa-solid fa-angle-right "></i>
                  </div> */}
                </div>
              </div>
              <div className="col">
                <div className="card h-100" style={{ border: "none" }}>
                  <span className="p-color DamionFont">location shooting</span>
                  <span className="p-color-2 fw-bold mb-1">ロケーション撮影</span>
                  <Link to={"/fullpack/2"}>
                    <img
                      src="img\locationimg.png"
                      className="card-img-top "
                      alt="..."
                    />
                  </Link>
                  {/* <div className="card-footer d-flex justify-content-between align-items-center p-color-2">
                    <small className="fs-5">
                      {" "}
                      Price<b>¥15,000~</b>
                    </small>
                    <i className="fa-solid fa-angle-right "></i>
                  </div> */}
                </div>
              </div>
              <div className="col">
                <div className="card h-100" style={{ border: "none" }}>
                  <span className="p-color DamionFont">promotion movie</span>
                  <span className="p-color-2 fw-bold mb-1"> プロモーションムービー</span>
                  <Link to={"/fullpack/3"}>
                    <img
                      src="img\promotion.png"
                      className="card-img-top "
                      alt="..."
                    />
                  </Link>
                  {/* <div className="card-footer d-flex justify-content-between align-items-center p-color-2">
                    <small className="fs-5">
                      {" "}
                      Price <b>¥15,000~</b>
                    </small>
                    <i className="fa-solid fa-angle-right "></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      <Question innerRef={questionRef}></Question>
      <Link to={"/flow"} style={{ textDecoration: "none" }}><Flowbutton></Flowbutton> </Link>
      <Footer></Footer>
    </>
  );
};
export default HomePage;
