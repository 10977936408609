import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import API, { path } from "../helper/Api";
import { FetchLogin } from "../reducers/user/actionCreator";
import { connect } from "react-redux";
interface Props {
    FetchLogin?: any;
}
const LoginPage = (Props: Props) => {
    let navigate = useNavigate();
    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    useEffect(() => {
        return () => { };
    }, []);

    const _handelRegisterClick = () => {
        navigate("/register", { replace: false });
    }
    const submitLogin = () => {
        API.post(`user/login`, {
         
            email: userName,
            password: password,
         
        })
            .then((data) => {
                if (data.data.status) {
                    localStorage.setItem("@tocken", data.data.token);
                    Props.FetchLogin();
                    toast(data.data.message, { type: 'error' });
                    navigate("/", { replace: false });
                } else {
                    toast("しばらくしてから再度、お試しください", { type: 'error' });           
                }
            })
            .catch((error) => {
                console.log(error);
                
                if (error.response) {
                    console.log("52");
                    if (error.response.data.valData) {
                        let dataerrer = error.response.data.valData.errors.map(
                            (item: any, index: number) => {
                                return { name: item.param, message: item.msg };
                            }
                        );
                        for (let index = 0; index < dataerrer.length; index++) {
                            const element = dataerrer[index];
                            toast(element.message, { type: 'error' });
                            console.log("5sdsdf2");
                        }
                    } else {
                        console.log("451");
                        toast(error.response.data.message, { type: 'error' });                        
                    }
                } else {
                    console.log("41");
                }
                console.log("error,error", error.response);

            });
    }
    useEffect(() => {
        return () => {};
      }, []);
      useEffect(() => {
        setTimeout(() => {
          scrollToTop()
        }, 300);
       
      }, [])
      const scrollToTop = () =>{ 
      
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    return (
        <>
         <ToastContainer />
         <HeaderNoBg></HeaderNoBg>
            <div className="container">
                <div className="row text-center">
                <Link className=" " to={'/'}> <img src="/img/logo.jpeg" className="mx-auto d-block mt-4" height={51} width={189}  alt="" />  </Link>
                </div>
            </div>
            <div className="container-fluid">
                <div className="container text-center">
                    <div className="row ">
                        <div className=" col-12 col-sm-2 col-md-2"></div>
                        <div className=" col-12 col-sm-8 col-md-8 py-5 ">
                            <form className=" py-5 border border-dark rounded form-width px-5">
                                <h3 className="text mt-1 text-center DamionFont">Login</h3>
                                <h2 className="mb-5">ログイン</h2>
                                <div className="row mb-3">
                                    <label htmlFor="inputuserid" className="col-sm-2 col-form-label text-lg-start">ID</label>
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control " id="inputEmail3" style={{ backgroundColor: "#EFEFEF" }} value={userName} onChange={(e) => {
                                            setuserName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="row mb-3 text-lg-start">
                                    <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">パスワード</label>
                                    <div className="col-sm-10">
                                        <input type="password" className="form-control" id="inputPassword3" style={{ backgroundColor: "#EFEFEF" }} value={password} onChange={(e) => {
                                            setpassword(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <button type="button" onClick={()=>{submitLogin();}} className="btn btn-outline-success btn-lg mx-auto d-block px-5 mt-5 mb-3 rounded-0 btn-width ">ログイン</button>
                               <Link to={"/forgot"} className="text-decoration-none  text-primary fs-6 fw-semibold  ">パスワードをお忘れの方</Link> 

                            </form>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2"></div>
                    </div>
                    <div>
                        <p className="fs-3">初めてご利用になる方</p>
                        <button type="button" onClick={() => {
                            _handelRegisterClick()
                        }} className="btn btn-outline-success btn-lg mx-auto d-block px-5 mb-5 rounded-0 btn-width ">新規登録</button>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>);
}
const mapStateToProps = (state: any) => ({
    //...state,
    is_login: state.user.is_login,
    first_name: state.user.first_name,
    last_name: state.user.last_name,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    FetchLogin: () => dispatch(FetchLogin()),
    // stopAction: () => dispatch(stopAction)
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
