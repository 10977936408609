import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducers';
import { composeWithDevTools } from 'redux-devtools-extension';
let store = createStore(rootReducer, compose(applyMiddleware(thunk)));
if (process.env.NODE_ENV === 'development') {
	store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}

export default store;
