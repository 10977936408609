import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import { ToastContainer, toast } from 'react-toastify';
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import Question from "../components/Question";
import Flowbutton from "../components/Flowbutton";
import { Link } from 'react-router-dom'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
interface Props {
    // history: any;
}
const Moviespage = (Props: Props) => {
    let navigate = useNavigate();
    const [delivery_option, setdelivery_option] = useState("")
    const [selected_add_on, setselected_add_on] = useState<Array<any>>([])
    const [addons, setaddons] = useState<Array<any>>([])
    const [, setstate] = useState({})

    let { id } = useParams();
    console.log("id", id);
    const [planData, setplanData] = useState<any>(undefined)

    useEffect(() => {
        if (id && id.length > 0) {
            fetchPlans(id || "")
            fetchAddons()

        }
        return () => { };
    }, [id]);
    const fetchPlans = (id: string) => {
        API.get(`plancategory/getbyid/${id}`)
            .then((data) => {
                console.log("patch paln", data.data);

                if (data.data.status) {
                    setplanData(data.data.data)


                } else {

                }
            })
            .catch((error) => {
                console.log(error);

                console.log("error,error", error.response);

            });
    }
    const fetchAddons = () => {
        API.get(`addon/getall/active`)
            .then((data) => {
                console.log("patch paln", data.data);

                if (data.data.status) {
                    setaddons(data.data.data)


                } else {

                }
            })
            .catch((error) => {
                console.log(error);

                console.log("error,error", error.response);

            });
    }



    const _submitCart = () => {
        let tocken = localStorage.getItem("@tocken");
        console.log("tocken",tocken);
        if(!tocken){
            toast("ログインを行い、購入してください", { type: 'error' });
            return;
        }
        
        if (delivery_option.length === 0) {
            toast("オプションを選択してください", { type: 'error' });
            return;

        }
       
        const AuthStr = "Bearer ".concat(tocken ? tocken : "");
        API.post(`user/cart`, { plan_id: id, addons: delivery_option }, {
            headers: { Authorization: AuthStr },
        })
            .then((data) => {
                console.log("patch paln", data.data);
                if (data.data.status) {
                    toast("商品がカートに追加されました", { type: 'success' });
                    setTimeout(() => {
                        navigate("/cart", { replace: false });
                    }, 2000);


                } else {
                    toast("しばらくしてから再度、お試しください", { type: 'error' });
                }
            })
            .catch((error) => {
                console.log(error);
                toast(error, { type: 'error' });
                console.log("error,error", error.response);

            });
    }
    useEffect(() => {
        return () => {};
      }, []);
      useEffect(() => {
        setTimeout(() => {
          scrollToTop()
        }, 300);
       
      }, [])
      const scrollToTop = () =>{ 
      
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    return (
        <>
            <ToastContainer />
            <HeaderNoBg></HeaderNoBg>
            {planData && <>
                <div className="container movie-height ">
                    <iframe src={planData.video_link} className="video-height" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="container pt-2 line-height border-bottom border-dark">
                    <div className="row  pt-5   padding-top-zero">
                        <div className="col-12 col-sm-8 my-3 movie-sample ">
                            <h2 className="lines8 DamionFont">{planData.title}</h2>
                            <h5 className="fw-bold in">{planData.japness_title}</h5>
                            <p id="wraptext" className="mt-3 in">『{planData.title}』は、<br /> {planData.description}
                            </p>
                        </div>
                        <div className="col col-sm-4 foat-left">
                            <div className="row ">
                                <div className="col a1">
                                    <p className=" float-center">オプション</p>
                                </div>
                                <div className="col a2">
                                
                                    <select className="form-select" aria-label="Default select example" defaultValue={delivery_option} value={delivery_option} onChange={(e) => {
                                        setdelivery_option(e.target.value)
                                    }}>
                                        <option selected value={""}>選択してください</option>
                                        {addons.map((item: any, index: number) => {
                                            return <option key={index} value={item.id}>{item.name}</option>;
                                        })}
                                    </select>
                                </div>
                                <button type="button" onClick={() => { _submitCart() }} className="btn  rounded my-5  py-3 btn-danger border-dark border-5">カートに入れる</button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container line-height border-bottom border-dark">
                    <div className="row lh-sm my-4">
                        <div className="col-12 col-sm-6 movie-sample">
                            <h5 className="fw-bold in">写真枚数</h5>
                            <p id="wraptext " className=" in">{planData.no_of__photo_sort_description}</p>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="container  line-height border-bottom border-dark">
                    <div className="row my-4">
                        <div className="col-12 col-sm-6 ">
                            <p className="fw-bold in movie-sample">
                                変更可能箇所</p>
                            {planData.feature_list.map((item: any, index: number) => { return <p key={index} className=""><i className="fa fagreen fa-check-square-o" aria-hidden="true"></i> {item.title}</p> })}

                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="container line-height border-bottom border-dark  ">
                    <div className="row my-4">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  in movie-sample">
                                各種オプション</p>
                            <div className="box-check  ">
                                {addons.map((item: any, index: number) => {
                                    return <p key={index} className=""><i className="fa fagreen fa-check-square-o" aria-hidden="true"></i> {item.name}</p>
                                })}
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="container pt-5 margin-top padding-top-zero">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  in movie-sample">
                            キャンセル料</p>
                            <div className="box-check  ">
                                {planData.cancel_list.map((item: any, index: number) => {
                                    return <p key={index} className=""><i className="fa fagreen fa-check-square-o" aria-hidden="true"></i> {item.title}</p>
                                })}
                            </div>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="container">
                    <Link to={"/flow"} className="text-decoration-none"><button type="button" className="btn border border-white text-dark shadow border-2 my-5 px-5 mx-auto d-block px-4 rounded" style={{ backgroundColor: "#B3D992" }}>ムービーの作り方 </button></Link>
                </div>
                <div className="container photo-right">
                    <div className="row  ">
                        <div className="col a1">
                            <p className=" float-center">オプション</p>
                        </div>
                        <div className="col a2">
                            <p className="">納品形態</p>
                            <select className="form-select" aria-label="Default select example" defaultValue={delivery_option} value={delivery_option} onChange={(e) => {
                                setdelivery_option(e.target.value)
                            }}>
                                <option selected>選択してください</option>
                                {addons.map((item: any, index: number) => {
                                    return <option key={index} value={item.id}>{item.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="row mx-auto d-block w-100">
                            <button type="button" onClick={() => { _submitCart() }} className="btn border-dark rounded-0 my-5  mx-auto d-block py-3 ">カートに入れる</button>
                        </div>

                    </div>

                </div>

                <Question></Question>
                <Flowbutton></Flowbutton>
            </>}
            <Footer></Footer>
        </>);
}
export default Moviespage;