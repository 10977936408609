import React, { useEffect, useState } from "react";

import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { colors } from "@material-ui/core";
interface Props {
  // history: any;
}
const Passwordpage = (Props: Props) => {
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
      <HeaderNoBg></HeaderNoBg>

      <div className="container-fulid py-5 my-5">
        <div className="container ">
          <div className="row">
            <div
              className="col-md-3 border-end  border-dark reg-btn-1"
              style={{ height: 413 }}
            >
                <ul className="mt-2 Registerpage-ul">
               <Link to={"/password"}> <a  className="text-dark">
                  <li className="Registerpage-padd">・登録内容の確認・変更</li>
                </a></Link>
                <Link to={"/reset"}>    <a href="" className="text-dark">
                  <li className="Registerpage-padd fw-semibold">
                    ・パスワードの変更
                  </li>
                </a></Link>
                
              <Link to={"/myorder"}> <a href="" className="text-dark">
                  <li className="Registerpage-padd fw-semibold">
                    ・入稿ページ
                  </li>
                </a></Link>
              </ul>
            </div>
            <div className="col-md-9 px-4 ">
              <div className="DamionFont">
                <span
                  className="fs-5 passpagecenter-1  DamionFont"
                  style={{ color: "#739654" }}
                >
                  Account Info
                </span>
                <span
                  className="fs-5 passpagecenter-2 fw-semibold"
                  style={{ color: "#739654" }}
                >
                  password
                </span>
                <h2 className="passpagecenter-3">パスワードの変更</h2>
              </div>
              <hr />

              {/* MY WORK  */}

              <div className="row ms-2 my-4">
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>メールアドレス</p>
                </div>
                <div className="col-md-8 col-sm-12">
                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="サンプルテキストサンプルテキスト "
                  />
                </div>
              </div>
              <div className="row ms-2 my-4">
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>ユーザーID</p>
                </div>
                <div className="col-md-8 col-sm-12">
                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="サンプルテキストサンプルテキスト "
                  />
                </div>
              </div>
              <div className="row ms-2 my-4">
                <div className="col-md-3 col-sm-12 fw-semibold">
                  <p>パスワード</p>
                </div>
                <div className="col-md-8 col-sm-12">
                  <input
                    type="text"
                    className="form-control shadow-inner"
                    id="inputText"
                    placeholder="サンプルテキストサンプルテキスト "
                  />
                </div>
              </div>
             
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 "></div>
            <div className="col-12 col-md-6 d-flex align-item-center justify-content-center align-center ">
              <Link to={"/Mypage"}>
                {" "}
                <button
                  type="button"
                  className="btn btn-outline-danger rounded-0 Registerpage-btn"
                >
                  編集する
                </button>
              </Link>
            </div>
          </div>
          <div className="row reg-btn-2">
            <ul className="mt-2 list-unstyled">
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・登録内容の確認・変更
                </li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd">・パスワードの変更</li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">
                  ・お支払い方法
                </li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">・配送先住所</li>
              </a>
              <a href="" className="text-dark">
                <li className="Registerpage-padd fw-semibold">・入稿ページ</li>
              </a>
            </ul>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
};
export default Passwordpage;
