import React, { useEffect, useState } from "react";

import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import {Link , useNavigate} from 'react-router-dom';

import { colors } from "@material-ui/core";
interface Props {
  // history: any;
}
const Mypage = (Props: Props) => {
  let navigate = useNavigate();
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
  useEffect(() => {
    setTimeout(() => {
      scrollToTop()
    }, 300);
   
  }, [])
  const scrollToTop = () =>{ 
  
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <>
               <HeaderNoBg></HeaderNoBg>
            <div className="container-fluid my-5">
    <div className="container spacemypage">
        <div className="row my-5">
            <div className="col-3"></div>
            <div className=" col-12 col-sm-12 col-md-6 py-5 ">
                <h4 className="p-color mypagecenter DamionFont">Mypage</h4>
                <h3 className="mypagecenter">マイページ</h3>
                <hr className="hr2  "/> 
                <div className="mt-4 center ">                  
                  <Link to={"/ragisterinfo"}><button type="button" className="btn rounded-0 btnwh btn-outline-dark btn-lg mb-4 mt-5 ">登録内容の確認・変更</button></Link> 
                  <Link to={"/reset"}> <button type="button" className="btn rounded-0  btnwh  btn-outline-dark btn-lg mb-4 mt-5">パスワードの変更</button> </Link>  
                  {/* <Link to={"/payment"}> <button type="button" className="btn rounded-0  btnwh btn-outline-dark btn-lg  my-4">お支払い方法</button> </Link>  */}
                {/* <button type="button" className="btn rounded-0  btnwh  btn-outline-dark btn-lg my-4 ">配送先住所</button>  */}
                <Link to={"/myorder"}> <button type="button" className="btn rounded-0 btnwh  btn-outline-dark btn-lg my-4 ">入稿ページ</button> </Link> 
                <button type="button" onClick={()=>{
                   localStorage.clear();   navigate("/", { replace: false });
                      navigate("/");

                }} className="btn rounded-0 btnwh  btn-outline-dark btn-lg my-4 ">ログアウト</button>
               
                </div>
                
            </div>
            <div className="col-3"></div> 
        </div>
    </div>
</div>


      <Footer></Footer>
    </>
  );
};
export default Mypage;
