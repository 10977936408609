import React, { useEffect, useState } from "react";
import API, { path } from "../helper/Api";
import { ToastContainer, toast } from "react-toastify";
import HeaderNoBg from "../components/HeaderNoBg";
import Footer from "../components/Footer";
import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";

interface Props {
    // history: any;
}
const CartPage = (Props: Props) => {
    let csrreat = "";
    let navigate = useNavigate();

    const [cart_item, setcart_item] = useState<Array<any>>([]);
    const [total, settotal] = useState(0);
    useEffect(() => {
        fetchPlans();
        return () => { };
    }, []);
    const fetchPlans = () => {
        let tocken = localStorage.getItem("@tocken");
        const AuthStr = "Bearer ".concat(tocken ? tocken : "");
        API.get(`user/cart`, {
            headers: { Authorization: AuthStr },
        })
            .then((data) => {
                console.log("patch paln", data.data);
                if (data.data.status) {
                    setcart_item(data.data.data);
                    let amount = 0;

                    for (let index = 0; index < data.data.data.length; index++) {
                        const element = data.data.data[index];
                        amount = amount + parseFloat(element.total);
                    }
                    settotal(amount);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);

                console.log("error,error", error.response);
            });
    };

    const UpdateCart = (items: Array<any>) => {
        let tocken = localStorage.getItem("@tocken");
        const AuthStr = "Bearer ".concat(tocken ? tocken : "");
        API.patch(
            `user/cart`,
            { items: items },
            {
                headers: { Authorization: AuthStr },
            }
        )
            .then((data) => {
                console.log("patch paln", data.data);
                if (data.data.status) {
                    fetchPlans();
                    toast("新たな商品がカートに追加されました", { type: "success" });
                } else {
                    toast("しばらくしてから再度、お試しください", {
                        type: "error",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                toast(error, { type: "error" });
                console.log("error,error", error.response);
            });
    };
    const gotoToOrder = () => {
        navigate("/address", { replace: false });
    };
    useEffect(() => {
        return () => {};
      }, []);
      useEffect(() => {
        setTimeout(() => {
          scrollToTop()
        }, 300);
       
      }, [])
      const scrollToTop = () =>{       
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
      };
    return (
        <>
            <ToastContainer />
            <HeaderNoBg></HeaderNoBg>
            <section className="h-100 h-custom">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12">
                            <div
                                className=" card-registration card-registration-2"
                                style={{ borderRadius: 15 }}
                            >
                                <div className="card-body p-0">
                                    <div className="row g-0">
                                        <div className="col-lg-7">
                                            <div className="p-5">
                                                <div className="row mb-4 d-flex justify-content-between align-items-center">
                                                    <div className="col-md-1  text-end"></div>
                                                    <div className="col-md-2 ">
                                                        <h6 className="text-black mb-0">商品</h6>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <h6 className="text-black mb-0"></h6>
                                                    </div>
                                                    <div className="col-md-1 float-end">
                                                        <h6 className="text-black mb-0">金額</h6>
                                                    </div>
                                                    <div className="col-md-2 d-flex text-center">
                                                        <h6 className="text-black mb-0">数量</h6>
                                                    </div>
                                                    <div className="col-md-1 text-center">
                                                        <h6 className="text-black mb-0">小計</h6>
                                                    </div>
                                                </div>
                                                {cart_item.map((item: any, index: number) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="row mb-4 d-flex justify-content-between align-items-center"
                                                        >
                                                            <div className="col-md-1 text-end">
                                                                <a
                                                                    href="#!"
                                                                    className="text-muted"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        let items = cart_item;
                                                                        items.splice(index, 1);
                                                                        setcart_item(items);
                                                                        UpdateCart(items);
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fas fa-times"
                                                                        style={{ color: "red" }}
                                                                    ></i>
                                                                </a>
                                                            </div>
                                                            <div className="col-md-2 col-12">
                                                            <iframe src={item.plan_category_data[0].video_link}  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" width={100} allowFullScreen></iframe>
                                                            </div>
                                                            <div className="col-md-3 ">
                                                                <h6 className="text-muted"></h6>
                                                                <h6 className=" mb-2">
                                                                    {item.plan_data[0].japness_title}
                                                                    <p className="fontSize">オプション: {item.addon_data[0].name} </p>
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-1  offset-lg-1">
                                                                <h6 className="mb-2">
                                                                    ¥
                                                                    {(
                                                                        item.amount + item.add_on_amount
                                                                    ).toLocaleString("hi-IN")}
                                                                </h6>
                                                            </div>
                                                            <div className="col-md-2  d-flex ">
                                                                <input
                                                                    id="form1"
                                                                    min="0"
                                                                    name="quantity"
                                                                    style={{ width: 45 }}
                                                                    value={item.qty}
                                                                    type="number"
                                                                    className="form-control form-control-sm mb-2"
                                                                    onChange={(e) => {
                                                                        let items = cart_item;
                                                                        items[index].qty = e.target.value;
                                                                        setcart_item(items);
                                                                        UpdateCart(items);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-1 ">
                                                                <h6 className="mb-2">
                                                                    ¥{item.total.toLocaleString("hi-IN")}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="vr"></div>
                                        <div className="col-lg-4 bg-grey">
                                            <div className="p-4">
                                                <div className="row lh-base">
                                                    <div className="col-6">
                                                        <h3 className="fw-semibold fs-5 ">
                                                            お買い物カゴの合計
                                                        </h3>
                                                        <h5 className="text-uppercase fs-6 pt-3">小計</h5>
                                                    </div>
                                                    <div className="col-4">
                                                        <br></br>
                                                        <br></br>
                                                        <h5>¥{total.toLocaleString("hi-IN")}</h5>
                                                    </div>
                                                    <div className="col-4"></div>
                                                </div>

                                                {/* <hr className="my-4" />
                                                    <div className="row lh-base">
                                                        <div className="col-4">
                                                            <h5 className="text-uppercase mb-0">配送</h5>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="mb-0 pb-0">
                                                                <ul className=" float-end ">
                                                                    <li value="1" className="">送料：¥560</li>
                                                                    <li value="2" className="">東京都に配送します。</li>
                                                                    <li value="3" className="text-danger fw-semibold">※住所を変更</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">

                                                        </div>
                                                    </div> */}

                                                <hr className="my-4" />
                                                <div className="row lh-base mb-5">
                                                    <div className="col">
                                                        <h5 className="text-uppercase">合計</h5>
                                                    </div>
                                                    <div className="col">
                                                        <div className=" ">
                                                            <h5 className="float-end cart-aling">
                                                                ¥{total.toLocaleString("hi-IN")}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="col"></div>
                                                </div>

                                               <button
                                                    type="button"
                                                    onClick={() => {
                                                        gotoToOrder();
                                                    }}
                                                    className="btn btn-outline-danger  btn-block w-100 btn-lg shadow-0 rounded-0"
                                                    data-mdb-ripple-color="dark"
                                                >
                                                    お支払いへ進む
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
};
export default CartPage;
